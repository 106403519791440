import { useTheme } from "@mui/material";
import Box from "./Box";

const HarmfulBadge = ({ color }: { color?: string }) => {
  const theme = useTheme();
  const styles = {
    badge: {
      backgroundColor: color || theme.palette.critical.main,
      border: "2px solid black",
      borderRadius: "50%",
      height: "12px",
      width: "12px",
    },
  };
  return <Box sx={styles.badge}></Box>;
};

export default HarmfulBadge;
