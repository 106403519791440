export const activeUserGroupsByRetrievals = [
  { id: "P1", value: "", parent: "", user: "P1" },
  {
    id: "1",
    value: 27,
    parent: "P1",
    label: "us_support_group@acmecorp.com",
  },
  {
    id: "2",
    value: 18,
    parent: "P1",
    label: "us_intern_group@acmecorp.com",
  },
  {
    id: "3",
    value: 14,
    parent: "P1",
    label: "sales_group@acmecorp.com",
  },
  {
    id: "4",
    value: 11,
    parent: "P1",
    label: "customer_care@acmecorp.com",
  },
];
