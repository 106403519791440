import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import Checkbox from "../../components/common/Checkbox";

const ExceptionConfig = ({
  setPolicyExceptionConfig,
  policyExceptionConfig,
}) => {
  const theme = useTheme();
  const styles = {
    card: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    label: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
  };
  return (
    <Box sx={styles.card}>
      <Typography variant="body2" color={theme.palette.surface50.main}>
        Grant Full Access
      </Typography>
      <Box sx={styles.label}>
        <Checkbox
          label="policyExceptionConfig"
          checked={policyExceptionConfig}
          onClick={() => setPolicyExceptionConfig(!policyExceptionConfig)}
        />
        <Typography variant="body2" color={theme.palette.surface80.main}>
          Users will be allowed full access to all documents
        </Typography>
      </Box>
    </Box>
  );
};

export default ExceptionConfig;
