import { useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";

interface SolidChipProps {
  text: string;
  color: string;
  textColor?: string;
}

export const SolidChip = ({ text, color, textColor }: SolidChipProps) => {
  const theme = useTheme();
  const styles = {
    chip: {
      width: "fit-content",
      padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
      borderRadius: "4px",
      backgroundColor: color,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  return (
    <Box sx={styles.chip}>
      <Typography
        variant="caption"
        color={textColor || theme.palette.surface10.main}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default SolidChip;
