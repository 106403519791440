import { useTheme } from "@mui/material";
import Button from "../../components/common/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../components/common/Dialog";
import Typography from "../../components/common/Typography";
import Box from "../../components/common/Box";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { TextField } from "../../components/common/TextField";
import moment from "moment";
import TextButton from "../../components/common/TextButton";
import Add from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { DraggableList } from "../../components/common/DraggableList";

const LabelField = ({ item, onDelete, onChange, provided }) => {
  const theme = useTheme();
  const styles = {
    box: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
  };

  return (
    <Box sx={styles.box} {...provided.dragHandleProps}>
      <DragIndicatorOutlinedIcon />
      <TextField
        fullWidth
        value={item?.name}
        onChange={(event) => onChange(event.target.value, item.id)}
      />
      <DeleteOutlineOutlinedIcon onClick={onDelete} />
    </Box>
  );
};

const AddClassificationLabels = ({ isOpen, handleClose, labelList }) => {
  const theme = useTheme();
  const [labels, setLabels] = useState(labelList);
  const styles = {
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    labelFields: {
      ul: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      },
    },
    addMoreBtn: {
      alignSelf: "flex-start",
    },
  };

  useEffect(() => {
    setLabels(labelList);
  }, [labelList]);

  useEffect(() => {
    console.log({ labels });
  }, [labels]);

  const handleSubmit = () => {
    localStorage.setItem("classificationLabelsData", JSON.stringify(labels));
    handleClose();
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(labels);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setLabels(items);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle handleClose={handleClose}>
        Edit Classification Labels
      </DialogTitle>
      <Box sx={styles.dialogContent}>
        <Typography color={theme.palette.surface80.main} variant="body2">
          Configure which labels are shown. Drag labels to change priority
        </Typography>
        <Box sx={styles.labelFields}>
          <DraggableList
            items={labels}
            onDragEnd={onDragEnd}
            component={(item: any, provided: any) => {
              return (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                  <LabelField
                    item={item}
                    onDelete={() =>
                      setLabels((prevState) =>
                        prevState?.filter((label) => label.id !== item.id)
                      )
                    }
                    onChange={(str, id) => {
                      console.log({ str });
                      setLabels((prevState) =>
                        prevState.map((arrItem) =>
                          arrItem.id === id
                            ? { ...arrItem, name: str }
                            : arrItem
                        )
                      );
                    }}
                    provided={provided}
                  />
                </div>
              );
            }}
          />
        </Box>
        <TextButton
          startIcon={<Add />}
          sx={styles.addMoreBtn}
          onClick={() =>
            setLabels((prevState) => [
              ...prevState,
              {
                id: (
                  Number(prevState[prevState?.length - 1]?.id) + 1
                ).toString(),
                name: "",
                addedOn: moment().format("YYYY-MM-DD | HH:mm:ss"),
                docsTagged: 0,
              },
            ])
          }
        >
          Add another
        </TextButton>
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddClassificationLabels;
