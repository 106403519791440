export const networkGraphData = {
  nodes: [
    {
      id: "rds1",
      name: "Google Drive",
      type: "GoogleDrive",
      versionDetails: "1.38 GB",
      cloud: "Google Drive",
      policyName: "",
      policyViolations: [
        { name: "Restricted topics in prompt session", count: 24 },
        { name: "Sensitive identifiers in prompt session", count: 12 },
        { name: "Identity violations in prompt session", count: 1 },
      ],
      sensitiveRecords: [
        {
          label: "PII-SSN",
          count: 1577,
        },
        {
          label: "Employee ID",
          count: 1423,
        },
      ],
      isViolation: false,
    },
    {
      id: "langchain1",
      name: "AcmeMortgageApp-1",
      fx: 120,
      fy: 150,
      type: "langchain",
      versionDetails: "0.2.16",
      cloud: "langchain",
      policyName: "Restricted topics, Sensitive Identifiers",
      policyViolations: [
        { name: "Sensitive identifiers in prompt session", count: 24 },
        { name: "Restricted topics in prompt session", count: 12 },
        { name: "Identity violations in prompt session", count: 1 },
      ],
      sensitiveMessages: {
        count: 37,
        entities: "PII - SSN, PII - CreditCard",
        topics: "Intellectual Property, Bias",
      },
      isViolation: false,
    },
    {
      id: "postgres1",
      name: "AWS Postgres (PGVector)",
      type: "postgres",
      versionDetails: "2.3 GB",
      cloud: "aws",
      policyName: "",
      policyViolations: [
        { name: "Restricted topics in prompt session", count: 3 },
        { name: "Sensitive identifiers in prompt session", count: 1 },
      ],
      sensitiveRecords: [
        {
          label: "PII-SSN",
          count: 1577,
        },
        {
          label: "Employee ID",
          count: 1423,
        },
      ],
      isViolation: true,
    },
    {
      id: "s31",
      name: "AWS S3",
      type: "s3",
      versionDetails: "2.3 GB",
      cloud: "aws",
      policyName: "",
      policyViolations: [
        { name: "Restricted topics in prompt session", count: 3 },
        { name: "Sensitive identifiers in prompt session", count: 1 },
      ],
      sensitiveRecords: [
        {
          label: "PII-SSN",
          count: 1577,
        },
        {
          label: "Employee ID",
          count: 1423,
        },
      ],
      isViolation: true,
    },
    {
      id: "llm1",
      name: "falcon-7b-bf16",
      type: "llm",
      versionDetails: "3.2 GB",
      cloud: "",
      policyName: "",
      policyViolations: [
        {
          name: "Trained with data from publicly exposed datastores",
          count: 1,
        },
        { name: "Fine tuned with data violating residency", count: 1 },
        {
          name: "Fine tuned on sensitive dataFine tuned on sensitive data",
          count: 1,
        },
      ],
      sensitiveRecords: [
        {
          label: "PII-SSN",
          count: 7238,
        },
      ],
      isViolation: true,
    },
    {
      id: "langchain2",
      name: "HealthCareApp-1",
      type: "langchain",
      fx: 120,
      fy: 300,
      versionDetails: "0.2.14",
      cloud: "langchain",
      policyName: "Restricted topics",
      policyViolations: [
        { name: "Restricted topics in prompt session", count: 36 },
        { name: "Sensitive identifiers in prompt session", count: 5 },
      ],
      sensitiveMessages: {
        count: 41,
        entities: "MRN",
        topics: "MedicalAdvice",
      },
      isViolation: false,
    },
    {
      id: "vespa1",
      name: "Vespa",
      type: "vespa",
      versionDetails: "90.02 GB",
      cloud: "",
      policyName: "",
      policyViolations: [],
      sensitiveRecords: [
        {
          label: "Github Token",
          count: 8,
        },
        {
          label: "AWS Access Key",
          count: 13,
        },
      ],
      isViolation: false,
    },
    //{
    //  id: "localFiles1",
    //  name: "Local PDF",
    //  type: "pdf",
    //  versionDetails: "2.11 GB",
    //  cloud: "aws",
    //  policyName: "",
    //  policyViolations: [
    //    { name: "Restricted topics in prompt session", count: 3 },
    //    { name: "Sensitive identifiers in prompt session", count: 1 },
    //  ],
    //  sensitiveRecords: [
    //    {
    //      label: "PII-SSN",
    //      count: 1577,
    //    },
    //    {
    //      label: "Employee ID",
    //      count: 1423,
    //    },
    //  ],
    //  isViolation: false,
    //},
    {
      id: "slackAPILoader1",
      name: "Slack API Loader",
      type: "slack",
      versionDetails: "304 MB",
      cloud: "",
      policyName: "",
      policyViolations: [
        { name: "Restricted topics in prompt session", count: 3 },
        { name: "Sensitive identifiers in prompt session", count: 1 },
      ],
      sensitiveRecords: [
        {
          label: "PII-SSN",
          count: 521,
        },
        {
          label: "Employee ID",
          count: 156,
        },
      ],
      isViolation: false,
    },
    {
      id: "sharepointLoader1",
      name: "SharePoint Loader",
      type: "sharepoint",
      versionDetails: "9.2 GB",
      cloud: "",
      policyName: "",
      policyViolations: [
        { name: "Restricted topics in prompt session", count: 3 },
        { name: "Sensitive identifiers in prompt session", count: 1 },
      ],
      sensitiveRecords: [
        {
          label: "PII-SSN",
          count: 1572,
        },
        {
          label: "Employee ID",
          count: 1424,
        },
      ],
      isViolation: false,
    },
    {
      id: "langchain3",
      name: "Proxima - SupportApp",
      fx: 300,
      fy: 150,
      type: "daxa",
      versionDetails: "4.1",
      cloud: "",
      policyName: "Restricted topics, Sensitive identifiers",
      policyViolations: [
        { name: "Restricted topics in prompt session", count: 36 },
      ],
      sensitiveMessages: {
        count: 22,
        entities: "-",
        topics: "ProductRoadmap, AWS Access Key, Github Token",
      },
      isViolation: true,
    },
    {
      id: "openAI1",
      name: "Open AI",
      type: "openAI",
      versionDetails: "GPT-4o",
      cloud: "",
      policyName: "",
      policyViolations: [
        //{ name: "Restricted topics in prompt session", count: 3 },
        //{ name: "Sensitive identifiers in prompt session", count: 1 },
      ],
      sensitiveRecords: [
        //{
        //  label: "PII-SSN",
        //  count: 1577,
        //},
        //{
        //  label: "Employee ID",
        //  count: 1423,
        //},
      ],
      isViolation: false,
    },
    {
      id: "langchain4",
      name: "ProductInsights",
      type: "langchain",
      fx: 300,
      fy: 300,
      versionDetails: "0.2.13",
      cloud: "langchain",
      policyName: "Restricted topics",
      policyViolations: [
        { name: "Restricted topics in prompt session", count: 41 },
      ],
      sensitiveMessages: {
        count: 41,
        entities: "",
        topics: "Product Roadmap",
      },
      isViolation: false,
    },
    {
      id: "notion1",
      name: "Notion",
      type: "Notion",
      versionDetails: "1.6 GB",
      cloud: "Notion",
      policyName: "",
      policyViolations: [
        { name: "Restricted topics in prompt session", count: 41 },
      ],
      sensitiveRecords: [
        {
          label: "PII-SSN",
          count: 1577,
        },
        {
          label: "Employee ID",
          count: 1423,
        },
      ],
      isViolation: false,
    },
    {
      id: "user1",
      appName: "AcmeMortgageApp-1",
      name: "Active Users",
      activeUsers: 3,
      type: "users",
      groups: [
        { name: "Sales", value: "1" },
        { name: "Underwriter", value: "1" },
        { name: "Customer Care", value: "1" },
      ],
      versionDetails: "2.3 GB",
    },
    {
      id: "user2",
      appName: "",
      name: "Active Users",
      activeUsers: 5,
      type: "users",
      groups: [
        { name: "Sales Group", value: "4" },
        { name: "Eng", value: "1" },
      ],
      versionDetails: "2.3 GB",
    },
    {
      id: "user3",
      appName: "",
      name: "Active Users",
      activeUsers: 3,
      type: "users",
      groups: [
        { name: "Intern Group", value: "1" },
        { name: "Support Group", value: "2" },
        { name: "Employee Group", value: "3" },
      ],
      versionDetails: "2.3 GB",
    },
    {
      id: "user4",
      appName: "ProductInsights",
      name: "Active Users",
      activeUsers: 1,
      groups: [{ name: "Sales Group", value: "1" }],
      type: "users",
      versionDetails: "2.3 GB",
    },
  ],
  links: [
    { source: "rds1", target: "langchain1", isViolation: true },
    { source: "langchain1", target: "postgres1", isViolation: true },
    { source: "langchain1", target: "llm1", isViolation: false },
    { source: "llm1", target: "langchain2", isViolation: false },
    { source: "s31", target: "langchain2", isViolation: false },
    { source: "langchain2", target: "vespa1", isViolation: false },
    { source: "vespa1", target: "langchain3", isViolation: true },
    { source: "openAI1", target: "langchain3", isViolation: true },
    { source: "langchain4", target: "vespa1", isViolation: false },
    { source: "langchain4", target: "notion1", isViolation: false },
    { source: "user1", target: "langchain1", isViolation: false },
    { source: "user2", target: "langchain2", isViolation: false },
    { source: "user3", target: "langchain3", isViolation: false },
    { source: "user4", target: "langchain4", isViolation: false },
    { source: "slackAPILoader1", target: "langchain3", isViolation: false },
    { source: "sharepointLoader1", target: "langchain3", isViolation: false },
  ],
};
