import { useTheme } from "@mui/material";
import Button from "../../components/common/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../components/common/Dialog";
import Typography from "../../components/common/Typography";
import Box from "../../components/common/Box";

const DeletePolicy = ({ open, onClose, onSubmit }) => {
  const theme = useTheme();
  const styles = {
    dialogContent: {
      padding: theme.spacing(2),
    },
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth={true}>
      <DialogTitle handleClose={onClose}>Delete Policy</DialogTitle>
      <Box sx={styles.dialogContent}>
        <Typography color={theme.palette.surface80.main} variant="body2">
          Are you sure you want to delete this policy? This action cannot be
          undone.
        </Typography>
      </Box>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onSubmit} variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePolicy;
