import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import KeyValueBlock from "../../components/common/KeyValueBlock";
import Typography from "../../components/common/Typography";
import ViewMoreBlock from "../../components/common/ViewMoreBlock";
import Chip from "../../components/common/Chip";
import StarIcon from "../../assets/img/star.svg";
import { useEffect, useState } from "react";
import Divider from "../../components/common/Divider";

const NUM_OF_DEFAULT_CHARACTERS = 500;

const ContextDetails = ({ initialContext, finalContext, response }) => {
  const noOfContexts = initialContext?.length;
  const [selectedContext, setSelectedContext] = useState(0);
  const theme = useTheme();
  const styles = {
    card: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    keyValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.75),
    },
    aiChip: {
      color: theme.palette.surface60.main,
      backgroundColor: "transparent",
      "& .MuiChip-avatar": {
        width: "10px",
        height: "10px",
      },
    },
    context: {},
    contextBtns: {
      display: "flex",
      gap: theme.spacing(2),
    },
    contextBtn: {
      cursor: "pointer",
    },
    divider: {
      backgroundColor: "#292D40",
    },
  };
  const showContextSelector = typeof initialContext !== "string";

  return (
    <Box sx={styles.card}>
      <Box sx={styles.keyValue}>
        <Typography variant="caption" color={theme.palette.surface40.main}>
          Initial Context
        </Typography>
        {showContextSelector ? (
          <Box sx={styles.keyValue}>
            <Box sx={styles.contextBtns}>
              {[...Array(noOfContexts)].map((_, index) => (
                <Typography
                  color={
                    selectedContext === index
                      ? theme.palette.surface100.main
                      : theme.palette.primaryBlue.main
                  }
                  onClick={() => setSelectedContext(index)}
                  sx={styles.contextBtn}
                >
                  {index + 1}
                </Typography>
              ))}
            </Box>
            <ViewMoreBlock
              value={
                initialContext && initialContext[selectedContext]
                  ? initialContext[selectedContext]?.snippet
                  : "-"
              }
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
        ) : (
          <ViewMoreBlock
            value={initialContext}
            length={NUM_OF_DEFAULT_CHARACTERS}
          />
        )}
      </Box>
      <Box sx={styles.keyValue}>
        <Typography variant="caption" color={theme.palette.surface40.main}>
          Final Context{" "}
          <Chip
            sx={styles.aiChip}
            label="AI Assisted"
            avatar={<img src={StarIcon} />}
          />
        </Typography>
        <ViewMoreBlock
          value={finalContext}
          length={NUM_OF_DEFAULT_CHARACTERS}
        />
      </Box>
      <Divider orientation="horizontal" sx={styles.divider} />
      <Box sx={styles.keyValue}>
        <Typography variant="caption" color={theme.palette.surface40.main}>
          Response
        </Typography>
        <ViewMoreBlock value={response} length={NUM_OF_DEFAULT_CHARACTERS} />
      </Box>
    </Box>
  );
};

export default ContextDetails;
