import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import ViewMoreBlock from "../../components/common/ViewMoreBlock";

const NUM_OF_CHARACTERS = 300;

const Snippet = ({ retrievedOn, snippet }) => {
  const theme = useTheme();
  const styles = {
    snippet: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };
  return (
    <Box sx={styles.snippet}>
      <Typography variant="caption" color={theme.palette.surface50.main}>
        Retrieved On: {retrievedOn}
      </Typography>
      <ViewMoreBlock value={snippet} length={NUM_OF_CHARACTERS} />
    </Box>
  );
};

export default Snippet;
