import moment from "moment";

export const classificationLabelsList = [
  {
    id: "1",
    name: "Highly Confidential",
    addedOn: moment().subtract(1, "days").format("YYYY-MM-DD | HH:mm:ss"),
    docsTagged: 103,
  },
  {
    id: "2",
    name: "Confidential",
    addedOn: moment()
      .subtract("1.4", "days")
      .subtract("18.18", "minutes")
      .format("YYYY-MM-DD | HH:mm:ss"),
    docsTagged: 56,
  },
  {
    id: "3",
    name: "Internal",
    addedOn: moment()
      .subtract("1", "days")
      .subtract("2.2", "minutes")
      .format("YYYY-MM-DD | HH:mm:ss"),
    docsTagged: 213,
  },
  {
    id: "4",
    name: "Restricted",
    addedOn: moment()
      .subtract("1", "days")
      .subtract("18.4", "minutes")
      .format("YYYY-MM-DD | HH:mm:ss"),
    docsTagged: 112,
  },
  {
    id: "5",
    name: "Public",
    addedOn: moment()
      .subtract("1", "days")
      .subtract("21.8", "minutes")
      .format("YYYY-MM-DD | HH:mm:ss"),
    docsTagged: 112,
  },
];
