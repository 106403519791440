import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Chip from "../../components/common/Chip";
import Typography from "../../components/common/Typography";

const UserPromptsCard = ({
  timestamp,
  prompt,
  label,
  color,
  selected,
  onSelect,
}) => {
  const theme = useTheme();
  const styles = {
    card: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.5),
      borderLeft: `1px solid ${color}`,
      padding: theme.spacing(2),
      cursor: "pointer",
      backgroundColor: selected ? theme.palette.surface0.main : "transparent",
    },
    chip: {
      height: "20px",
      "&.MuiChip-root": {
        width: "fit-content",
        borderRadius: "16px",
        border: `1px solid ${color}`,
        backgroundColor: color,
        fontSize: "10px",
        color: theme.palette.surface100.main,
      },
      "& .MuiChip-label": {
        padding: "2px 8px 2px 8px",
      },
    },
    font13: {
      fontSize: "13px",
    },
    selectedChip: {
      position: "absolute",
      width: "15px",
      height: "15px",
      right: "-7.5px",
      top: "45%",
      backgroundColor: theme.palette.surface0.main,
      transform: "rotate(45deg)",
    },
  };
  return (
    <Box sx={styles.card} onClick={onSelect}>
      <Typography color={theme.palette.surface50.main} sx={styles.font13}>
        {timestamp}
      </Typography>
      <Typography color={theme.palette.surface80.main} sx={styles.font13}>
        {prompt}
      </Typography>
      <Chip label={label} sx={styles.chip} />
      {/*{selected && <Box sx={styles.selectedChip}></Box>}*/}
    </Box>
  );
};

export default UserPromptsCard;
