import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Button from "../../components/common/Button";
import JiraIcon from "../../assets/img/jira.svg";
import Typography from "../../components/common/Typography";
import Chip from "../../components/common/Chip";
import StarIcon from "../../assets/img/star.svg";

const AIInsightsTip = ({
  showAiInsight,
  show,
  text,
  onCreateTicket,
}: {
  showAiInsight?: boolean;
  show: boolean;
  text: any;
  onCreateTicket: any;
}) => {
  const theme = useTheme();
  const styles = {
    box: {
      visibility: show ? "" : "hidden",
      padding: theme.spacing(1),
      width: "50%",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "rgba(255, 101, 91, 0.2)",
      alignItems: "center",
      borderRadius: "4px",
    },
    text: {
      width: "50%",
      display: "flex",
    },
    jiraBtn: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      textTransform: "none",
      paddingY: theme.spacing(1),
      color: theme.palette.surface70.main,
      cursor: "pointer",
    },
    aiChip: {
      color: theme.palette.surface60.main,
      backgroundColor: "transparent",
      "& .MuiChip-avatar": {
        width: "10px",
        height: "10px",
      },
    },
  };
  return (
    <Box sx={styles.box}>
      {showAiInsight ? (
        <Chip
          sx={styles.aiChip}
          label="AI Insight"
          avatar={<img src={StarIcon} />}
        />
      ) : null}
      <Box sx={styles.text}>
        <Typography variant="caption" color={theme.palette.surface90.main}>
          {text}
        </Typography>
      </Box>
      <Box>
        <Button
          variant="outlined"
          startIcon={<img src={JiraIcon} alt="jira" />}
          sx={styles.jiraBtn}
          onClick={onCreateTicket}
        >
          Create Ticket
        </Button>
      </Box>
    </Box>
  );
};

export default AIInsightsTip;
