export const applicationDetails = [
  {
    id: "ac6a5d3c-7777-7777-a3cd-e64818d279d2",
    frameworkSummary: {
      family: "langchain",
      name: "Proxima - SupportApp",
      version: "0.1.7",
      vectorDB: "Chroma",
      location: "/var/rag/langchain/chroma_db",
      embeddingModel: "OpenAIEmbeddings",
      models: {
        name: "text-davinci-003",
        vendor: "openai",
      },
    },
    instanceDetails: {
      host: "ip-172-31-27-73",
      path: "/var/Proxima - SupportApp",
      runtime: "virtual-machine",
      ip: "172-31-27-73",
      language: "python",
      languageVersion: "3.10.12",
    },
    providerInfo: {
      cloudType: "aws",
      region: "us-east-1",
      aws: {
        region: null,
        accountId: "099873896856",
        instanceId: "i-0dcd5d4e62ab12101",
      },
      gcp: null,
    },
    chains: [
      {
        name: "RetrievalQA",
        vectorDbs: [
          {
            name: "Chroma",
            version: "0.4.7",
            embeddingModel: "OpenAIEmbeddings",
            location: "/var/rag/langchain/chroma_db",
            bucket: [
              {
                name: "Local Files (PDF)",
                sensitiveData: "PII-SSN, Credit Cards, PII-SSN, Email",
                type: "s3",
              },
            ],
            packageInfo: {
              projectHomePage: "home",
              documentationUrl: "https://docs.trychroma.com",
              pypiUrl: "https://pypi.org/project/chromadb",
              licenceType: "Apache Software License",
              installedVia: "PIP installation",
              location: "/var/rag/langchain/chroma_db",
            },
          },
        ],
        model: {
          name: "text-davinci-003",
          vendor: "openai",
        },
      },
    ],
    promptStats: {
      hour: {
        promptCount: 11,
        sensitivePromptCount: 2,
        sensitiveResponseCount: 3,
        sensitiveContextCount: 3,
        sensitiveMessages: 8,
      },
      day: {
        promptCount: 100,
        sensitivePromptCount: 17,
        sensitiveResponseCount: 17,
        sensitiveContextCount: 20,
        sensitiveMessages: 53,
      },
      week: {
        promptCount: 1018,
        sensitivePromptCount: 18,
        sensitiveResponseCount: 12,
        sensitiveContextCount: 6,
        sensitiveMessages: 36,
      },
    },
    promptTrends: {
      week: [
        {
          at: "2024-10-11",
          promptCount: 142,
          sensitivePromptCount: 1,
          sensitiveResponseCount: 3,
          sensitiveContextCount: 2,
          sensitiveMessages: 6,
        },
        {
          at: "2024-10-10",
          promptCount: 121,
          sensitivePromptCount: 2,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 0,
          sensitiveMessages: 3,
        },
        {
          at: "2024-10-09",
          promptCount: 154,
          sensitivePromptCount: 1,
          sensitiveResponseCount: 2,
          sensitiveContextCount: 1,
          sensitiveMessages: 4,
        },
        {
          at: "2024-10-08",
          promptCount: 118,
          sensitivePromptCount: 1,
          sensitiveResponseCount: 2,
          sensitiveContextCount: 0,
          sensitiveMessages: 3,
        },
        {
          at: "2024-10-07",
          promptCount: 161,
          sensitivePromptCount: 7,
          sensitiveResponseCount: 2,
          sensitiveContextCount: 1,
          sensitiveMessages: 10,
        },
        {
          at: "2024-10-06",
          promptCount: 164,
          sensitivePromptCount: 6,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 2,
          sensitiveMessages: 9,
        },
        {
          at: "2024-10-05",
          promptCount: 158,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 0,
          sensitiveMessages: 1,
        },
      ],
      day: [
        {
          at: "2024-10-11T14:00:00Z",
          promptCount: 11,
          sensitivePromptCount: 1,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 1,
          sensitiveMessages: 3,
        },
        {
          at: "2024-10-11T13:00:00Z",
          promptCount: 0,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 0,
        },
        {
          at: "2024-10-11T12:00:00Z",
          promptCount: 5,
          sensitivePromptCount: 2,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 1,
          sensitiveMessages: 4,
        },
        {
          at: "2024-10-11T11:00:00Z",
          promptCount: 9,
          sensitivePromptCount: 1,
          sensitiveResponseCount: 4,
          sensitiveContextCount: 2,
          sensitiveMessages: 7,
        },
        {
          at: "2024-10-11T10:00:00Z",
          promptCount: 1,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 0,
          sensitiveMessages: 1,
        },
        {
          at: "2024-10-11T09:00:00Z",
          promptCount: 3,
          sensitivePromptCount: 1,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 1,
          sensitiveMessages: 3,
        },
        {
          at: "2024-10-11T08:00:00Z",
          promptCount: 7,
          sensitivePromptCount: 1,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 3,
          sensitiveMessages: 4,
        },
        {
          at: "2024-10-11T07:00:00Z",
          promptCount: 8,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 1,
          sensitiveMessages: 1,
        },
        {
          at: "2024-10-11T06:00:00Z",
          promptCount: 0,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 0,
        },
        {
          at: "2024-10-11T05:00:00Z",
          promptCount: 9,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 0,
          sensitiveMessages: 1,
        },
        {
          at: "2024-10-11T04:00:00Z",
          promptCount: 1,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 1,
          sensitiveMessages: 1,
        },
        {
          at: "2024-10-11T03:00:00Z",
          promptCount: 8,
          sensitivePromptCount: 2,
          sensitiveResponseCount: 2,
          sensitiveContextCount: 2,
          sensitiveMessages: 6,
        },
        {
          at: "2024-10-11T02:00:00Z",
          promptCount: 1,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 0,
        },
        {
          at: "2024-10-11T01:00:00Z",
          promptCount: 14,
          sensitivePromptCount: 3,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 1,
          sensitiveMessages: 5,
        },
        {
          at: "2024-10-11T00:00:00Z",
          promptCount: 0,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 0,
        },
        {
          at: "2024-10-10T23:00:00Z",
          promptCount: 2,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 0,
        },
        {
          at: "2024-10-10T22:00:00Z",
          promptCount: 3,
          sensitivePromptCount: 1,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 1,
        },
        {
          at: "2024-10-10T21:00:00Z",
          promptCount: 0,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 0,
        },
        {
          at: "2024-10-10T20:00:00Z",
          promptCount: 6,
          sensitivePromptCount: 2,
          sensitiveResponseCount: 2,
          sensitiveContextCount: 2,
          sensitiveMessages: 6,
        },
        {
          at: "2024-10-10T19:00:00Z",
          promptCount: 0,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 0,
        },
        {
          at: "2024-10-10T18:00:00Z",
          promptCount: 7,
          sensitivePromptCount: 2,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 3,
          sensitiveMessages: 6,
        },
        {
          at: "2024-10-10T17:00:00Z",
          promptCount: 3,
          sensitivePromptCount: 1,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 1,
          sensitiveMessages: 2,
        },
        {
          at: "2024-10-10T16:00:00Z",
          promptCount: 2,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 1,
          sensitiveMessages: 2,
        },
        {
          at: "2024-10-10T15:00:00Z",
          promptCount: 0,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 0,
        },
      ],
      hour: [
        {
          at: "2024-10-11T14:50:00Z",
          promptCount: 2,
          sensitivePromptCount: 1,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 1,
        },
        {
          at: "2024-10-11T14:40:00Z",
          promptCount: 4,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 2,
          sensitiveContextCount: 2,
          sensitiveMessages: 4,
        },
        {
          at: "2024-10-11T14:30:00Z",
          promptCount: 1,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 0,
        },
        {
          at: "2024-10-11T14:20:00Z",
          promptCount: 3,
          sensitivePromptCount: 1,
          sensitiveResponseCount: 1,
          sensitiveContextCount: 1,
          sensitiveMessages: 3,
        },
        {
          at: "2024-10-11T14:10:00Z",
          promptCount: 1,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 0,
        },
        {
          at: "2024-10-11T14:00:00Z",
          promptCount: 0,
          sensitivePromptCount: 0,
          sensitiveResponseCount: 0,
          sensitiveContextCount: 0,
          sensitiveMessages: 0,
        },
      ],
    },
  },
];
