import { useTheme } from "@mui/material";
import Box from "./Box";
import { useEffect } from "react";
import * as d3 from "d3";

const CommonTreeMap = ({ index, colorScale, data, graphHeight }) => {
  const theme = useTheme();
  const styles = {
    container: {
      height: "60%",
      width: "100%",
      overflow: "hidden",
    },
  };

  var root = d3
    .stratify()
    .id(function (d: any) {
      return d.id;
    })
    .parentId(function (d: any) {
      return d.parent;
    })(data);

  root.sum(function (d: any) {
    return +d.value;
  });

  const values = data?.map((d) => (d?.value ? Number(d?.value) : NaN));
  const minValue = d3.min(values);
  const [min, max] = d3.extent(values);

  const drawTreeMap = () => {
    const element = d3.selectAll(`.${index}`);
    element.selectAll("svg").remove();
    var margin = { top: 0, right: 0, bottom: 0, left: 0 },
      height = graphHeight - margin.top - margin.bottom;
    const parentWidth = document.getElementById(index)?.clientWidth || 100;
    const width = parentWidth - margin.left - margin.right;

    var svg = d3
      .select(`#${index}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    d3.treemap().size([width, height]).padding(1)(root);

    const rects = svg
      .selectAll("rect")
      .data(root.leaves())
      .enter()
      .append("rect")
      .attr("id", (d: any) => `rect-${d.data.id}`)
      .attr("class", (d: any) =>
        Number(d.data.value) >= Number(10) ? "rect-text" : "rect-no-text"
      )
      .attr("x", function (d: any) {
        return d.x0;
      })
      .attr("y", function (d: any) {
        return d.y0;
      })
      .attr("width", function (d: any) {
        return d.x1 - d.x0;
      })
      .attr("height", function (d: any) {
        return d.y1 - d.y0;
      })
      .style("stroke", "black")
      .style("fill", (d: any) => colorScale(d.data.tag))
      .on("click", (event, d) => {});

    // and to add the text labels
    const texts = svg
      .selectAll("rect-text")
      .data(
        Object.values(root.leaves())?.filter(
          (data) => Number(data?.value) >= Number(10)
        )
      )
      .enter()
      .append("foreignObject")
      .on("mouseover", (event, d: any) => {
        const element = document.getElementById(`rect-${d.data.id}`);
      })
      .on("click", (event, d) => {})
      .attr("x", function (d: any) {
        return d.x0 + 5;
      }) // +10 to adjust position (more right)
      .attr("y", function (d: any) {
        return d.y0 + 5;
      }) // +20 to adjust position (lower)
      .attr("width", (d: any) => d.x1 - d.x0 - 5)
      .attr("height", (d: any) => d.y1 - d.y0)
      .append("xhtml:p")
      .style("fill", "black")
      .html((d: any) => {
        return /*html*/ `<div class="treemap-rect">
          <div class="treemap-rect-header">
            ${d.data.violation ? '<div class="treemap-error-badge"></div>' : ""}
            <div style="font-size:10px;word-break:break-all;width:100%">${
              d.data.label
            }</div>
          </div>
          <div style="font-size:8px">${`${d.data.value <= 9 ? "0" : ""}${
            d.data.value
          }`}</div>
        </div>`;
      });
  };

  useEffect(() => {
    if (data?.length) drawTreeMap();
  }, [data]);

  return (
    <Box sx={styles.container}>
      <div id={index} className={index}></div>
    </Box>
  );
};

export default CommonTreeMap;
