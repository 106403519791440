import { IconButton, useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import { useState } from "react";
import Checkbox from "../../components/common/Checkbox";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Chip from "../../components/common/Chip";
import StarIcon from "../../assets/img/star.svg";

const entities = [
  {
    group: "Finance",
    labels: [
      "US Credit Card Number",
      "US Bank Account Number",
      "IBAN Code",
      "US ITIN",
    ],
  },
  {
    group: "PII",
    labels: [
      "IP Address",
      "US Social Security Number",
      "US Passport Number",
      "US Driver's License",
    ],
  },
  {
    group: "Secrets",
    labels: [
      "GitHub Access Token",
      "Slack Access Token",
      "AWS Access Key",
      "AWS Secret Key",
      "Private Key",
      "DSA Private Key",
      "Encrypted Private Key",
      "Elliptic Curve Private Key",
      "OpenSSH Private Key",
      "RSA Private Key",
      "Google Account Private Key",
      "Github Fine Grained Token",
      "Azure Client Secret Key",
    ],
  },
];

const topics = [
  {
    group: "Financial",
    labels: [],
  },
  {
    group: "Corporate Documents",
    labels: [],
  },
  {
    group: "Medical",
    labels: [],
  },
  {
    group: "Harmful",
    labels: [],
  },
];

const SemanticGuardrailConfig = ({
  selectedLabels,
  setSelectedLabels,
  isAIAssist,
}) => {
  const [selectedTab, setSelectedTab] = useState("Entity");
  const theme = useTheme();
  const styles = {
    card: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      borderTop: `1px solid ${theme.palette.surface20.main}`,
    },
    label: (label) => ({
      backgroundColor:
        selectedTab === label ? theme.palette.surface0.main : "transparent",
      padding: theme.spacing(2),
      cursour: "pointer",
    }),
    width_50: {
      width: "50%",
    },
    checkbox: {
      "& .MuiSvgIcon-root": { fontSize: "20px", width: "fit-content" },
    },
    labelName: {
      display: "flex",
      gap: theme.spacing(1.25),
      alignItems: "start",
    },
    aiChip: {
      backgroundColor: "transparent",
      color: theme.palette.surface60.main,
      "& .MuiChip-avatar": {
        width: "10px",
        height: "10px",
      },
    },
  };

  const labels = [
    {
      label: "Entity",
      value: "entites",
    },
    {
      label: "Topic",
      value: "topics",
    },
  ];

  const checkIfChecked = (group) => {
    if (selectedTab === "Entity") {
      return selectedLabels?.entities?.includes(group);
    } else {
      return selectedLabels?.topics?.includes(group);
    }
  };

  const getSelectedLabels = () => {
    if (selectedTab === "Entity") {
      return selectedLabels?.entities?.length;
    } else {
      return selectedLabels?.topics?.length;
    }
  };

  const labelList = selectedTab === "Entity" ? entities : topics;

  const selectedLabelList =
    selectedTab === "Entity"
      ? selectedLabels?.entities
      : selectedLabels?.topics;

  const getCountOfSelectedLabels = (label) => {
    if (label === "Entity") {
      return selectedLabels?.entities?.length || 0;
    } else {
      return selectedLabels?.topics?.length || 0;
    }
  };

  return (
    <Box sx={styles.card}>
      <Box>
        {labels.map((label) => (
          <Box
            sx={styles.label(label?.label)}
            onClick={() => setSelectedTab(label?.label)}
          >
            <Typography variant="body2" color={theme.palette.surface90.main}>
              {label?.label} Groups
            </Typography>
            <Typography variant="caption" color={theme.palette.surface60.main}>
              {getCountOfSelectedLabels(label?.label)} Selected
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.surface5.main,
        }}
      >
        <Box
          sx={{
            padding: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.surface20.main}`,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color={theme.palette.surface100.main}>
            {selectedTab} Groups
          </Typography>
          {isAIAssist && (
            <Chip
              sx={styles.aiChip}
              label="AI Assisted"
              avatar={<img src={StarIcon} />}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "50%",
              borderRight: `1px solid ${theme.palette.surface20.main}`,
            }}
          >
            <Box
              sx={{
                padding: theme.spacing(2),
                borderBottom: `1px solid ${theme.palette.surface20.main}`,
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>Available (4)</Typography>
            </Box>

            <Box
              sx={{
                padding: theme.spacing(2),
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(3),
                height: "400px",
                overflowY: "auto",
              }}
            >
              {labelList?.map((entity) => (
                <Box sx={styles.labelName}>
                  <Checkbox
                    label={entity?.group}
                    checked={checkIfChecked(entity?.group)}
                    sx={styles.checkbox}
                    onClick={() => {
                      if (selectedTab === "Entity") {
                        if (selectedLabels?.entities?.includes(entity?.group)) {
                          setSelectedLabels({
                            ...selectedLabels,
                            entities: selectedLabels?.entities.filter(
                              (item) => item !== entity?.group
                            ),
                          });
                        } else {
                          setSelectedLabels({
                            ...selectedLabels,
                            entities: [
                              ...selectedLabels?.entities,
                              entity?.group,
                            ],
                          });
                        }
                      } else {
                        console.log(selectedLabels?.topics, entity?.group);
                        if (selectedLabels?.topics?.includes(entity?.group)) {
                          setSelectedLabels({
                            ...selectedLabels,
                            topics: selectedLabels?.topics.filter(
                              (item) => item !== entity?.group
                            ),
                          });
                        } else {
                          setSelectedLabels({
                            ...selectedLabels,
                            topics: [...selectedLabels?.topics, entity?.group],
                          });
                        }
                      }
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Typography
                      variant="body2"
                      color={theme.palette.surface80.main}
                    >
                      {entity?.group}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: theme.spacing(0.5),
                      }}
                    >
                      {entity?.labels?.map((label) => (
                        <Typography
                          variant="caption"
                          color={theme.palette.surface50.main}
                        >
                          {label}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              width: "50%",
            }}
          >
            <Box
              sx={{
                padding: theme.spacing(2),
                borderBottom: `1px solid ${theme.palette.surface20.main}`,
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                Selected ({getSelectedLabels()})
              </Typography>
            </Box>

            <Box
              sx={{
                padding: theme.spacing(2),
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(3),
                height: "400px",
                overflowY: "auto",
              }}
            >
              {selectedLabelList?.map((entity) => (
                <Box
                  sx={{ ...styles.labelName, justifyContent: "space-between" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Typography
                      variant="body2"
                      color={theme.palette.surface80.main}
                    >
                      {entity}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: theme.spacing(0.5),
                      }}
                    >
                      {labelList
                        ?.find((label) => label?.group === entity)
                        ?.labels?.map((label) => (
                          <Typography
                            variant="caption"
                            color={theme.palette.surface50.main}
                          >
                            {label}
                          </Typography>
                        ))}
                    </Box>
                  </Box>
                  <IconButton
                    sx={{
                      width: "20px",
                      padding: "0 !important",
                      height: "16px",
                    }}
                    onClick={() => {
                      if (selectedTab === "Entity") {
                        setSelectedLabels({
                          ...selectedLabels,
                          entities: selectedLabels?.entities.filter(
                            (item) => item !== entity
                          ),
                        });
                      } else {
                        setSelectedLabels({
                          ...selectedLabels,
                          topics: selectedLabels?.topics.filter(
                            (item) => item !== entity
                          ),
                        });
                      }
                    }}
                  >
                    <CloseRoundedIcon
                      sx={{
                        width: "16px",
                        color: theme.palette.primaryBlue.main,
                      }}
                    />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SemanticGuardrailConfig;
