import moment from "moment";

const newDocs = [
  {
    name: "support-faqs.pdf",
    type: "pdf",
    confidentialityTag: "Internal",
    path: "https://acme-corp.google-drive.com/support-docs/support-faqs.pdf",
    source: "Google Drive",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 08:34:00",
    },
    size: "235.7 MB",
    sensitiveData: {
      value: 10,
      outOf: 35,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 4,
      outOf: 12,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "PII - SSN",
        count: 1,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["customer_care@acmecorp.com"],
  },
  {
    name: "feature-roadmap-2021.md",
    type: "md",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.sharepoint.com/product-docs/feature-roadmap-2021.md",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 07:12:10",
    },
    size: "0.52 GB",
    sensitiveData: {
      value: 20,
      outOf: 50,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 6,
      outOf: 15,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [
      {
        name: "Finance",
        count: 5,
      },
    ],
    sensitiveLabels: [],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "onboarding.docx",
    type: "docx",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.sharepoint.com/employee/onboarding.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 09:23:15",
    },
    size: "431.59 MB",
    sensitiveData: {
      value: 30,
      outOf: 50,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 12,
      outOf: 25,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: [
      "sales_group@acmecorp.com",
      "us_intern_group@acmecorp.com",
    ],
  },
  {
    name: "historical-loans-9dec-2023.pdf",
    type: "pdf",
    confidentialityTag: "Restricted",
    path: "https://acme-corp.s3.amazon.com/financials/historical-loans-9dec-2023.pdf",
    source: "S3",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(3, "days").format("YYYY-MM-DD") + " 06:56:03",
    },
    size: "564.8 MB",
    sensitiveData: {
      value: 30,
      outOf: 68,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 9,
      outOf: 23,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [
      {
        name: "Bias",
        count: 1,
      },
    ],
    sensitiveLabels: [
      {
        name: "Financial",
        count: 13,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "product-briefing-notes.md",
    type: "md",
    confidentialityTag: "Public",
    path: "https://acme-corp.notion.com/briefings/product-briefing-notes.md",
    source: "Notion",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(4, "days").format("YYYY-MM-DD") + " 11:45:30",
    },
    size: "75.2 MB",
    sensitiveData: {
      value: 5,
      outOf: 30,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 0,
      outOf: 10,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "PII - SSN",
        count: 1,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["us_intern_group@acmecorp.com"],
  },
  {
    name: "financial-report-q1-2023.docx",
    type: "docx",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.sharepoint.com/finance/reports/financial-report-q1-2023.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt: moment().subtract(1, "day").format("YYYY-MM-DD") + " 12:34:56",
    },
    size: "377.75 MB",
    sensitiveData: {
      value: 45,
      outOf: 60,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 25,
      outOf: 50,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: [
      "us_support_group@acmecorp.com",
      "customer_care@acmecorp.com",
    ],
  },
  {
    name: "product-dev-changelog.md",
    type: "md",
    confidentialityTag: "Public",
    path: "https://acme-corp.google-drive.com/docs/product-dev-changelog.md",
    source: "Google Drive",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 07:23:45",
    },
    size: "112.5 MB",
    sensitiveData: {
      value: 2,
      outOf: 20,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 1,
      outOf: 8,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "PII - SSN",
        count: 1,
      },
      {
        name: "AWS Secret Key",
        count: 16,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["us_intern_group@acmecorp.com"],
  },
  {
    name: "customer-feedback-jan-2024.docx",
    type: "docx",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.sharepoint.com/feedback/customer-feedback-jan-2024.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 09:14:55",
    },
    size: "290.29 MB",
    sensitiveData: {
      value: 35,
      outOf: 50,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 15,
      outOf: 30,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [
      {
        name: "Finance",
        count: 10,
      },
    ],
    sensitiveLabels: [],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "market-analysis-2024.docx",
    type: "docx",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.google-drive.com/reports/market-analysis-2024.docx",
    source: "Google Drive",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 10:22:34",
    },
    size: "432.8 MB",
    sensitiveData: {
      value: 20,
      outOf: 50,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 8,
      outOf: 20,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "PII - SSN",
        count: 2,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["customer_care@acmecorp.com"],
  },
  {
    name: "sales-pitch-ideas.md",
    type: "md",
    confidentialityTag: "Internal",
    path: "https://acme-corp.notion.com/sales/sales-pitch-ideas.md",
    source: "Notion",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 15:13:21",
    },
    size: "178.4 MB",
    sensitiveData: {
      value: 5,
      outOf: 30,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 2,
      outOf: 10,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Finance",
        count: 1,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["us_intern_group@acmecorp.com"],
  },
  {
    name: "legal-contract-terms.pdf",
    type: "pdf",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.s3.amazon.com/legal/legal-contract-terms.pdf",
    source: "S3",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(3, "days").format("YYYY-MM-DD") + " 08:17:00",
    },
    size: "625.5 MB",
    sensitiveData: {
      value: 40,
      outOf: 60,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 20,
      outOf: 40,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "AWS Access Key",
        count: 4,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "annual-summary-report-2023.docx",
    type: "docx",
    confidentialityTag: "Restricted",
    path: "https://acme-corp.sharepoint.com/reports/annual-summary-report-2023.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 12:45:12",
    },
    size: "81.53 MB",
    sensitiveData: {
      value: 35,
      outOf: 70,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 18,
      outOf: 35,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "PII - SSN",
        count: 2,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: [
      "us_support_group@acmecorp.com",
      "us_intern_group@acmecorp.com",
    ],
  },
  {
    name: "business-strategy-planning.pdf",
    type: "pdf",
    confidentialityTag: "Internal",
    path: "https://acme-corp.google-drive.com/business/business-strategy-planning.pdf",
    source: "Google Drive",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(4, "days").format("YYYY-MM-DD") + " 08:32:09",
    },
    size: "554.2 MB",
    sensitiveData: {
      value: 25,
      outOf: 40,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 10,
      outOf: 25,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["customer_care@acmecorp.com"],
  },
  {
    name: "market-research-2024.pdf",
    type: "pdf",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.sharepoint.com/financials/market-research-2024.pdf",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 14:23:45",
    },
    size: "347.26 MB",
    sensitiveData: {
      value: 0,
      outOf: 45,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 0,
      outOf: 15,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "internal-training-guide.pdf",
    type: "pdf",
    confidentialityTag: "Internal",
    path: "https://acme-corp.notion.com/training/internal-training-guide.pdf",
    source: "Notion",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(5, "days").format("YYYY-MM-DD") + " 07:10:50",
    },
    size: "140.8 MB",
    sensitiveData: {
      value: 0,
      outOf: 67,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 0,
      outOf: 23,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["us_intern_group@acmecorp.com"],
  },
  {
    name: "company-financials-2023.docx",
    type: "docx",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.sharepoint.com/financials/company-financials-2023.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 08:30:00",
    },
    size: "215.63 MB",
    sensitiveData: {
      value: 45,
      outOf: 65,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 20,
      outOf: 40,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "AWS Access Key",
        count: 3,
      },
      {
        name: "Finance",
        count: 10,
      },
      {
        name: "PII - SSN",
        count: 5,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "marketing-budget-2023.pdf",
    type: "pdf",
    confidentialityTag: "Restricted",
    path: "https://acme-corp.google-drive.com/marketing/marketing-budget-2023.pdf",
    source: "Google Drive",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 11:00:00",
    },
    size: "489.7 MB",
    sensitiveData: {
      value: 15,
      outOf: 30,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 5,
      outOf: 15,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Secrets",
        count: 2,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["customer_care@acmecorp.com"],
  },
  {
    name: "employee-training.pdf",
    type: "pdf",
    confidentialityTag: "Internal",
    path: "https://acme-corp.sharepoint.com/employee/training/employee-training.pdf",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(3, "days").format("YYYY-MM-DD") + " 09:10:20",
    },
    size: "170.70 MB",
    sensitiveData: {
      value: 5,
      outOf: 25,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 2,
      outOf: 8,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["us_intern_group@acmecorp.com"],
  },
  {
    name: "product-specifications-2024.docx",
    type: "docx",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.s3.amazon.com/products/product-specifications-2024.docx",
    source: "S3",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(4, "days").format("YYYY-MM-DD") + " 13:00:00",
    },
    size: "550.0 MB",
    sensitiveData: {
      value: 25,
      outOf: 50,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 12,
      outOf: 30,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "AWS Access Key",
        count: 2,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "customer-feedback-survey-2023.md",
    type: "md",
    confidentialityTag: "Public",
    path: "https://acme-corp.notion.com/feedback/customer-feedback-survey-2023.md",
    source: "Notion",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 16:55:30",
    },
    size: "125.6 MB",
    sensitiveData: {
      value: 5,
      outOf: 15,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 1,
      outOf: 3,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["customer_care@acmecorp.com"],
  },
  {
    name: "financial-audit-2023.docx",
    type: "docx",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.sharepoint.com/audit/financial-audit-2023.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 10:55:00",
    },
    size: "93.99 MB",
    sensitiveData: {
      value: 50,
      outOf: 80,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 22,
      outOf: 55,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Financial",
        count: 15,
      },
      {
        name: "PII - SSN",
        count: 10,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "customer-service-guidelines.pdf",
    type: "pdf",
    confidentialityTag: "Internal",
    path: "https://acme-corp.google-drive.com/guidelines/customer-service-guidelines.pdf",
    source: "Google Drive",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(3, "days").format("YYYY-MM-DD") + " 12:22:10",
    },
    size: "289.1 MB",
    sensitiveData: {
      value: 8,
      outOf: 18,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 3,
      outOf: 9,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["us_intern_group@acmecorp.com"],
  },
  {
    name: "employee-reimbursement.md",
    type: "md",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.sharepoint.com/employee/employee-reimbursement.md",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 14:11:15",
    },
    size: "0.55 GB",
    sensitiveData: {
      value: 7,
      outOf: 20,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 4,
      outOf: 10,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "PII - SSN",
        count: 1,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "market-research-analysis-2024.pdf",
    type: "pdf",
    confidentialityTag: "Restricted",
    path: "https://acme-corp.s3.amazon.com/research/market-research-analysis-2024.pdf",
    source: "S3",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(4, "days").format("YYYY-MM-DD") + " 15:45:30",
    },
    size: "640.0 MB",
    sensitiveData: {
      value: 10,
      outOf: 30,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 3,
      outOf: 12,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [
      {
        name: "Corporate Documents",
        count: 2,
      },
    ],
    sensitiveLabels: [
      {
        name: "Secrets",
        count: 1,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "quarterly-report-2024.docx",
    type: "docx",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.sharepoint.com/reports/quarterly-report-2024.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 12:25:00",
    },
    size: "0.51 GB",
    sensitiveData: {
      value: 40,
      outOf: 60,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 18,
      outOf: 50,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Finance",
        count: 10,
      },
      {
        name: "PII - SSN",
        count: 5,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "business-strategy-2024.pdf",
    type: "pdf",
    confidentialityTag: "Restricted",
    path: "https://acme-corp.s3.amazon.com/strategies/business-strategy-2024.pdf",
    source: "S3",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 17:10:20",
    },
    size: "450.5 MB",
    sensitiveData: {
      value: 12,
      outOf: 35,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 6,
      outOf: 10,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Financial",
        count: 18,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["customer_care@acmecorp.com"],
  },
  {
    name: "employee-onboarding-jan-2024.md",
    type: "md",
    confidentialityTag: "Internal",
    path: "https://acme-corp.sharepoint.com/employee/employee-onboarding-jan-2024.md",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(3, "days").format("YYYY-MM-DD") + " 08:40:50",
    },
    size: "113.47 MB",
    sensitiveData: {
      value: 7,
      outOf: 25,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 3,
      outOf: 8,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "PII - SSN",
        count: 1,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["us_intern_group@acmecorp.com"],
  },
  {
    name: "sales-training-materials-2024.docx",
    type: "docx",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.google-drive.com/sales/sales-training-materials-2024.docx",
    source: "Google Drive",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(4, "days").format("YYYY-MM-DD") + " 09:25:00",
    },
    size: "625.0 MB",
    sensitiveData: {
      value: 22,
      outOf: 50,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 10,
      outOf: 25,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "PII - SSN",
        count: 4,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "company-wide-announcement.pdf",
    type: "pdf",
    confidentialityTag: "Public",
    path: "https://acme-corp.notion.com/announcements/company-wide-announcement.pdf",
    source: "Notion",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 13:25:00",
    },
    size: "100.5 MB",
    sensitiveData: {
      value: 2,
      outOf: 12,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 0,
      outOf: 3,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["customer_care@acmecorp.com"],
  },
  {
    name: "product-roadmap-2024.docx",
    type: "docx",
    confidentialityTag: "Internal",
    path: "https://acme-corp.sharepoint.com/roadmap/product-roadmap-2024.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 10:40:30",
    },
    size: "258.68 MB",
    sensitiveData: {
      value: 10,
      outOf: 35,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 5,
      outOf: 12,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["us_intern_group@acmecorp.com"],
  },
  {
    name: "yearly-budget-plan-2024.pdf",
    type: "pdf",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.s3.amazon.com/budgets/yearly-budget-plan-2024.pdf",
    source: "S3",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(3, "days").format("YYYY-MM-DD") + " 12:15:30",
    },
    size: "500.7 MB",
    sensitiveData: {
      value: 18,
      outOf: 45,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 9,
      outOf: 20,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Financial",
        count: 17,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "sales-report-2024.docx",
    type: "docx",
    confidentialityTag: "Internal",
    path: "https://acme-corp.sharepoint.com/reports/sales-report-2024.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(4, "days").format("YYYY-MM-DD") + " 13:30:00",
    },
    size: "0.77 GB",
    sensitiveData: {
      value: 15,
      outOf: 30,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 8,
      outOf: 18,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "meeting-notes-2024.docx",
    type: "docx",
    confidentialityTag: "Internal",
    path: "https://acme-corp.sharepoint.com/financials/meeting-notes-2024.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 16:50:00",
    },
    size: "186.78 MB",
    sensitiveData: {
      value: 5,
      outOf: 12,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 1,
      outOf: 5,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Secrets",
        count: 1,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["us_intern_group@acmecorp.com"],
  },
  {
    name: "customer-feedback-summary-2024.docx",
    type: "docx",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.google-drive.com/feedback/customer-feedback-summary-2024.docx",
    source: "Google Drive",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(3, "days").format("YYYY-MM-DD") + " 09:40:00",
    },
    size: "320.0 MB",
    sensitiveData: {
      value: 8,
      outOf: 22,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 4,
      outOf: 12,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "company-policies-2024.pdf",
    type: "pdf",
    confidentialityTag: "Restricted",
    path: "https://acme-corp.notion.com/policies/company-policies-2024.pdf",
    source: "Notion",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 08:10:30",
    },
    size: "540.6 MB",
    sensitiveData: {
      value: 5,
      outOf: 18,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 2,
      outOf: 5,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "marketing-presentation-2024.pptx",
    type: "pptx",
    confidentialityTag: "Public",
    path: "https://acme-corp.s3.amazon.com/marketing/marketing-presentation-2024.pptx",
    source: "S3",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(4, "days").format("YYYY-MM-DD") + " 10:10:10",
    },
    size: "350.7 MB",
    sensitiveData: {
      value: 4,
      outOf: 14,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 1,
      outOf: 6,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "legal-notice-2024.docx",
    type: "docx",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.sharepoint.com/legal/legal-notice-2024.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 15:30:30",
    },
    size: "187.06 MB",
    sensitiveData: {
      value: 28,
      outOf: 50,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 12,
      outOf: 35,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Finance",
        count: 5,
      },
      {
        name: "PII - SSN",
        count: 7,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "health-advice-2024.pdf",
    type: "pdf",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.sharepoint.com/health/health-advice-2024.pdf",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 14:05:10",
    },
    size: "309.15 MB",
    sensitiveData: {
      value: 15,
      outOf: 30,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 8,
      outOf: 15,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [
      {
        name: "Medical",
        count: 4,
      },
    ],
    sensitiveLabels: [],
    policyViolations: [],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "mortgage-advice-2024.pdf",
    type: "pdf",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.google-drive.com/mortgage/mortgage-advice-2024.pdf",
    source: "Google Drive",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(3, "days").format("YYYY-MM-DD") + " 16:40:30",
    },
    size: "350.3 MB",
    sensitiveData: {
      value: 20,
      outOf: 50,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 12,
      outOf: 30,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["customer_care@acmecorp.com"],
  },
  {
    name: "mortgage-terms-2024.docx",
    type: "docx",
    confidentialityTag: "Restricted",
    path: "https://acme-corp.s3.amazon.com/mortgages/mortgage-terms-2024.docx",
    source: "S3",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 12:50:25",
    },
    size: "175.9 MB",
    sensitiveData: {
      value: 5,
      outOf: 18,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 2,
      outOf: 8,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "PII - SSN",
        count: 3,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "financial-guide-2024.md",
    type: "md",
    confidentialityTag: "Internal",
    path: "https://acme-corp.sharepoint.com/financials/financial-guide-2024.md",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 17:05:00",
    },
    size: "0.50 GB",
    sensitiveData: {
      value: 10,
      outOf: 30,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 4,
      outOf: 10,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Medical",
        count: 2,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["us_intern_group@acmecorp.com"],
  },
  {
    name: "mortgage-rate-2023.pdf",
    type: "pdf",
    confidentialityTag: "Restricted",
    path: "https://acme-corp.sharepoint.com/financials/mortgage-rate-2023.pdf",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 09:45:30",
    },
    size: "0.56 GB",
    sensitiveData: {
      value: 8,
      outOf: 22,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 4,
      outOf: 10,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Finance",
        count: 5,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "mortgage-application-form-2024.pdf",
    type: "pdf",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.sharepoint.com/mortgages/mortgage-application-form-2024.pdf",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(4, "days").format("YYYY-MM-DD") + " 18:20:45",
    },
    size: "0.57 GB",
    sensitiveData: {
      value: 18,
      outOf: 40,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 9,
      outOf: 25,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "health-insurance-guide-2024.docx",
    type: "docx",
    confidentialityTag: "Internal",
    path: "https://acme-corp.s3.amazon.com/insurance/health-insurance-guide-2024.docx",
    source: "S3",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(3, "days").format("YYYY-MM-DD") + " 15:35:00",
    },
    size: "270.2 MB",
    sensitiveData: {
      value: 12,
      outOf: 28,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 6,
      outOf: 15,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Medical",
        count: 54,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },

  {
    name: "mortgage-summary-2024.pdf",
    type: "pdf",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.sharepoint.com/mortgages/mortgage-summary-2024.pdf",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 11:15:20",
    },
    size: "0.62 GB",
    sensitiveData: {
      value: 22,
      outOf: 50,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 14,
      outOf: 35,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["customer_care@acmecorp.com"],
  },
  {
    name: "health-records-2024.docx",
    type: "docx",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.s3.amazon.com/health/health-records-2024.docx",
    source: "S3",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(4, "days").format("YYYY-MM-DD") + " 10:10:15",
    },
    size: "620.1 MB",
    sensitiveData: {
      value: 35,
      outOf: 50,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 20,
      outOf: 40,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "mortgage-rate-2024.pdf",
    type: "pdf",
    confidentialityTag: "Restricted",
    path: "https://acme-corp.notion.com/mortgages/mortgage-rate-2024.pdf",
    source: "Notion",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 09:45:30",
    },
    size: "180.3 MB",
    sensitiveData: {
      value: 8,
      outOf: 22,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 4,
      outOf: 10,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Finance",
        count: 5,
      },
    ],
    policyViolations: [],
    authorizedGroups: ["sales_group@acmecorp.com"],
  },
  {
    name: "mortgage-loans-2024.pdf",
    type: "pdf",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.sharepoint.com/mortgages/mortgage-loans-2024.pdf",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(2, "days").format("YYYY-MM-DD") + " 11:15:20",
    },
    size: "120.46 MB",
    sensitiveData: {
      value: 22,
      outOf: 50,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 14,
      outOf: 35,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["customer_care@acmecorp.com"],
  },
  {
    name: "employee-records-2024.docx",
    type: "docx",
    confidentialityTag: "Highly Confidential",
    path: "https://acme-corp.sharepoint.com/financials/employee-records-2024.docx",
    source: "SharePoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(4, "days").format("YYYY-MM-DD") + " 10:10:15",
    },
    size: "0.70 GB",
    sensitiveData: {
      value: 35,
      outOf: 50,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 20,
      outOf: 40,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
    },
    harmfulLabels: [],
    sensitiveLabels: [],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
];

export const documents = [
  {
    name: "support-kb.pdf",
    type: "pdf",
    path: "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
    source: "SharePoint",
    confidentialityTag: "Internal",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 17:43:19",
    },
    size: "408.02 MB",
    sensitiveData: {
      value: 7,
      outOf: 10,
    },
    sensitiveContent: {
      value: 2,
      outOf: 4,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Harmful",
      value: 3,
    },
    harmfulLabels: [
      {
        name: "Racial Bias",
        count: 3,
      },
    ],
    sensitiveLabels: [
      {
        name: "EC Private Key",
        count: 1,
      },
      {
        name: "Github Token",
        count: 3,
      },
      {
        name: "Financial",
        count: 2,
      },
      { name: "Product Roadmap", count: 1 },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
    aiInsight:
      "Multiple snippets with Harmful content detected. Create a ticket to edit or remove content from the document.",
    createTicketTitle:
      "Ticket name: Edit or remove harmful content from document https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
    showAiInsight: false,
  },
  {
    name: "financial-forecast.pdf",
    type: "pdf",
    confidentialityTag: "Confidential",
    path: "https://acme-corp.sharepoint.com/financials/financial-forecast.pdf",
    source: "sharepoint",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(3, "days").format("YYYY-MM-DD") + " 06:56:03",
    },
    size: "60.37 MB",
    sensitiveData: {
      value: 45,
      outOf: 92,
    },
    overprovisioned: 1,
    sensitiveContent: {
      value: 11,
      outOf: 43,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Financial",
        count: 13,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: [
      "us_support_group@acmecorp.com",
      "us_intern_group@acmecorp.com",
      "customer_care@acmecorp.com",
      "sales_group@acmecorp.com",
      "us_employee_group@acmecorp.com",
    ],
    aiInsight:
      "This document has a high number of user groups accessing valuable data. Create a ticket to restrict data access.",
    createTicketTitle:
      "This document has a high number of user groups accessing valuable data.",
    showAiInsight: true,
  },
  {
    name: "channels: #support",
    type: "Slack",
    confidentialityTag: "Default",
    path: "org: acme-corp.slack.com, channels: #support",
    source: "Slack",
    aiInsight:
      "Multiple AWS Access Key has been ingested from Slack Channel: #support. Create a ticket to clean the document source and re-ingest.",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 10:43:12",
    },
    size: "304.52 MB",
    sensitiveData: {
      value: 12,
      outOf: 16,
    },
    overprovisioned: 0,
    sensitiveContent: {
      value: 5,
      outOf: 30,
      isHarmful: true,
    },

    snippetImpact: {
      tag: "Sensitive",
    },
    harmfulLabels: [
      {
        name: "Bias",
        count: 1,
      },
    ],
    sensitiveLabels: [
      {
        name: "AWS Access Key",
        count: 8,
      },
      {
        name: "Github Token",
        count: 3,
      },
      {
        name: "Financial",
        count: 1,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: [
      "us_support_group@acmecorp.com",
      "us_intern_group@acmecorp.com",
    ],
  },
  {
    name: "historical-loans.pdf",
    type: "pdf",
    path: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
    source: "Google Drive",
    confidentialityTag: "Internal",
    database: { name: "postgres", updatedAt: "2024-09-20 10:43:12" },
    size: "127.9 MB",
    sensitiveData: {
      value: 16,
      outOf: 46,
    },
    sensitiveContent: {
      value: 13,
      outOf: 56,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
      labels: ["Toxic Content", "Racial Bias", "Insensitive Content"],
    },
    harmfulLabels: [
      {
        name: "Bias",
        count: 1,
      },
    ],
    sensitiveLabels: [
      {
        name: "PII - SSN",
        count: 2,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: [
      "us_employee_group@acmecorp.com",
      "sales_group@acmecorp.com",
    ],
  },
  {
    name: "acme-feature-roadmap-2024Q4.docx",
    type: "docx",
    path: "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
    source: "SharePoint",
    confidentialityTag: "Confidential",
    database: {
      name: "Vespa",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 19:31:52",
    },
    size: "336.80 MB",
    sensitiveData: {
      value: 3,
      outOf: 3,
    },
    sensitiveContent: {
      value: 0,
      outOf: 3,
      isHarmful: false,
    },
    snippetImpact: {
      tag: "Sensitive",
      value: 3,
    },
    harmfulLabels: [],
    sensitiveLabels: [{ name: "Product Roadmap", count: 3 }],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },
  {
    name: "product-kb.md",
    type: "md",
    path: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
    source: "Notion DB",
    confidentialityTag: "Highly Confidential",
    database: {
      name: "Chroma DB",
      updatedAt:
        moment().subtract(1, "days").format("YYYY-MM-DD") + " 07:27:32",
    },
    size: "34.52 MB",
    sensitiveData: {
      value: 12,
      outOf: 39,
    },
    sensitiveContent: {
      value: 5,
      outOf: 26,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
      labels: [],
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Product Roadmap",
        count: 12,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["pm_group@acmecorp.com"],
  },
  {
    name: "health-advice.pdf",
    type: "pdf",
    path: "s3://health-data-etl-38738/kb/health-advice.pdf",
    source: "S3",
    confidentialityTag: "Confidential",
    database: { name: "Vespa", updatedAt: "2024-09-20 10:43:12" },
    size: "65.29 MB",
    sensitiveData: {
      value: 6,
      outOf: 28,
    },
    sensitiveContent: {
      value: 4,
      outOf: 12,
      isHarmful: true,
    },
    snippetImpact: {
      tag: "",
      labels: [],
    },
    harmfulLabels: [],
    sensitiveLabels: [
      {
        name: "Medical Advice",
        count: 36,
      },
      {
        name: "PHI - MRN",
        count: 5,
      },
    ],
    policyViolations: ["Sensitive Identifier in Prompt Session"],
    authorizedGroups: ["us_support_group@acmecorp.com"],
  },

  // New docs
  ...newDocs,
];
