export const llms = [
  {
    cdDatastoreRiskScore: 0,
    name: "falcon-7b-bf16",
    administrator: null,
    type: "llm",
    "metadata.cloudAccountId": "099873896856",
    "metadata.createdAt": "2023-07-13 16:15:06.168000",
    size: "14.69 GB",
    mountLocation: "",
    datastoreId: "25c33b99-7719-4444-5555-ba4ee28539e3",
    assetId:
      "arn:aws:sagemaker:us-east-1:099873896856:endpoint/hf-llm-falcon-7b-bf16",
    attackstatus: true,
    indirectAttackStatus: false,
    sensitiveInfo: [
      {
        count: 2079,
        name: "social_security_numbers",
      },
      {
        count: 10012,
        name: "credit_cards",
      },
      {
        count: 16349,
        name: "drivers_licenses",
      },
      {
        count: 27250,
        name: "mobile_numbers",
      },
      {
        count: 4385,
        name: "nih",
      },
    ],
    datastoreType: "managed",
  },
  {
    cdDatastoreRiskScore: 0,
    name: "cerebras/Cerebras-GPT-590M",
    administrator: null,
    type: "llm",
    "metadata.cloudAccountId": "099873896856",
    "metadata.createdAt": "2023-07-28 11:02:30.720000",
    size: "4.5389 GB",
    mountLocation: "/data/home/model_data",
    datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
    assetId: "arn:aws:ec2:us-east-1:099873896856:instance/i-081f271c3fdd6a72e",
    attackstatus: false,
    indirectAttackStatus: false,
    sensitiveInfo: [
      {
        count: 2079,
        name: "social_security_numbers",
      },
      {
        count: 10012,
        name: "credit_cards",
      },
      {
        count: 16349,
        name: "drivers_licenses",
      },
      {
        count: 27250,
        name: "mobile_numbers",
      },
    ],
    datastoreType: "self-managed",
  },
  {
    cdDatastoreRiskScore: 0,
    name: "OpenAI",
    administrator: null,
    type: "llm",
    "metadata.cloudAccountId": "099873896856",
    "metadata.createdAt": "2023-07-28 11:02:30.720000",
    size: "4.5389 GB",
    mountLocation: "/data/home/model_data",
    datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
    assetId: "arn:aws:ec2:us-east-1:099873896856:instance/i-081f271c3fdd6a72e",
    attackstatus: false,
    cloud: "openai",
    status: "Active",
    indirectAttackStatus: false,
    sensitiveInfo: [],
    datastoreType: "managed",
  },
  {
    cdDatastoreRiskScore: 0,
    name: "gpt2",
    administrator: null,
    type: "llm",
    "metadata.cloudAccountId": "099873896856",
    "metadata.createdAt": "2023-07-28 11:11:53.400000",
    size: "1.021 GB",
    mountLocation: "/data/home/model_data",
    datastoreId: "7cd5c0fa-4321-4f89-bd9b-e9979b823c30",
    assetId: "arn:aws:ec2:us-east-1:099873896856:instance/i-05f66db16bae61bb0",
    attackstatus: false,
    indirectAttackStatus: false,
    sensitiveInfo: [
      {
        count: 1526,
        name: "social_security_numbers",
      },
      {
        count: 28081,
        name: "credit_cards",
      },
      {
        count: 27566,
        name: "drivers_licenses",
      },
      {
        count: 25019,
        name: "mobile_numbers",
      },
    ],
    datastoreType: "self-managed",
  },
  {
    cdDatastoreRiskScore: 0,
    name: "palm2-bison-cd--001",
    administrator: null,
    type: "llm",
    "metadata.cloudAccountId": "099873896856",
    "metadata.createdAt": "2023-09-13 16:15:06.168000",
    size: "",
    mountLocation: "",
    datastoreId: "152716d2-167d-46ec-a5db-60595b4b9c37",
    assetId:
      "projects:673251266950:locations:us-central1:models:2229534703222784000",
    attackstatus: false,
    indirectAttackStatus: false,
    sensitiveInfo: [
      {
        count: 1271,
        name: "social_security_numbers",
      },
    ],
    datastoreType: "managed",
    cloud: "gcp",
  },
  {
    cdDatastoreRiskScore: 0,
    name: "falcon-7b-instruct-bf16",
    administrator: null,
    type: "llm",
    "metadata.cloudAccountId": "099873896856",
    "metadata.createdAt": "2023-08-13 16:15:06.168000",
    size: "14.69 GB",
    mountLocation: "",
    datastoreId: "319348dd-e1f0-4cdd-9b7f-e93c1241b14b",
    assetId:
      "arn:aws:sagemaker:us-east-1:099873896856:training-job/smjs-c-hf-llm-falcon-7b-bf16-20230727-013639",
    attackstatus: false,
    indirectAttackStatus: false,
    sensitiveInfo: [
      {
        count: 2079,
        name: "social_security_numbers",
      },
      {
        count: 10012,
        name: "credit_cards",
      },
      {
        count: 16349,
        name: "drivers_licenses",
      },
      {
        count: 27250,
        name: "mobile_numbers",
      },
    ],
    datastoreType: "managed",
  },
];
