export const snippetsByLabels = [
  { id: "P1", value: "", parent: "", user: "P1" },
  {
    id: "1",
    value: 43,
    parent: "P1",
    tag: "Valuable",
    label: "PII - SSN",
  },
  {
    id: "2",
    value: 21,
    parent: "P1",
    tag: "Harmful",
    label: "PII - CC",
  },
  {
    id: "3",
    value: 20,
    parent: "P1",
    tag: "Valuable",
    label: "Product Roadmap",
  },
  {
    id: "4",
    value: 17,
    parent: "P1",
    tag: "Harmful",
    label: "AWS Access Key",
  },
  {
    id: "5",
    value: 14,
    parent: "P1",
    tag: "Harmful",
    label: "Github Token",
  },
  {
    id: "6",
    value: 11,
    parent: "P1",
    tag: "Sensitive",
    label: "Bias",
  },
  {
    id: "7",
    value: 7,
    parent: "P1",
    tag: "Sensitive",
    label: "Bias",
  },
];

export const sources = [
  { id: "P1", value: "", parent: "", user: "P1" },
  {
    id: "1",
    value: 43,
    parent: "P1",
    tag: "google",
    label: "GoogleDrive",
  },
  {
    id: "2",
    value: 39,
    parent: "P1",
    tag: "sharepoint",
    label: "SharePoint",
  },
  {
    id: "3",
    value: 34,
    parent: "P1",
    tag: "slack",
    label: "Slack",
  },
];
