import { useTheme } from "@mui/material";
import Box from "../common/Box";
import Typography from "../common/Typography";
import Divider from "../common/Divider";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Bar from "../common/Bar";
import { getIcon, getImageForNode } from "../../utils/commonUtils";
import { DASHBOARD_RINGS } from "../../mock-data/dashboardRingsData";
import Button from "../common/Button";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TextButton from "../common/TextButton";
import { applicationsList } from "../../mock-data/applications";

const NetworkGraphCard = ({ nodeDetails, getPolicyDetails }) => {
  const apps = useSelector((state: any) => state.dashboard.apps);
  const theme = useTheme();
  const history = useHistory();
  const styles = {
    cardContainer: {
      display: "flex",
      width: "100%",
      height: "450px",
      flexDirection: "column",
      gap: theme.spacing(3),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      boxShadow: `0px 0px 10px 0px ${theme.palette.surface0.main}, 0px 2px 3px -2px rgba(9, 12, 31, 0.70)`,
      paddingY: theme.spacing(2),
      overflowY: "hidden",
    },
    headerWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      paddingX: theme.spacing(2),
    },
    cardHeader: {
      display: "flex",
      gap: theme.spacing(1.25),
      alignItems: "center",
    },
    nodeDetails: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    divider: {
      height: "12px",
    },
    versionDetails: {
      display: "flex",
      gap: theme.spacing(1),
    },
    violationText: {
      fontSize: "28px",
      marginRight: theme.spacing(1),
    },
    barCell: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    subSectionHeader: {
      paddingX: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    scrollableContainer: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      gap: theme.spacing(2),
    },
    flexContainer: {
      display: "flex",
      gap: theme.spacing(1),
      paddingX: theme.spacing(2),
    },
    topicBlock: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.25),
    },
    tableCell: {
      width: "60%",
    },
    usersContainer: {
      paddingY: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    activeUsers: {
      paddingX: theme.spacing(2),
    },
    activeUserCount: {
      fontFamily: "Manrope",
      fontSize: "28px",
    },
    paddingY: {
      paddingBottom: theme.spacing(2),
    },
    nodeName: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    viewDetailsBtn: {
      marginLeft: "auto",
    },
  };

  const gotoAppDetails = (name) => {
    const appDetails = applicationsList.find((app) => app.name === name);
    if (appDetails?.id) history.push(`/framework/${appDetails?.id}`);
  };

  const TopicValueBlock = ({ label, value }) => (
    <Box sx={styles.topicBlock}>
      <Typography variant="caption" color={theme.palette.surface30.main}>
        {label}
      </Typography>
      <Typography variant="caption" color={theme.palette.surface60.main}>
        {value}
      </Typography>
    </Box>
  );

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.headerWrapper}>
        <Box sx={styles.cardHeader}>
          <Box
            sx={{
              border: `1px solid ${theme.palette.surface30.main}`,
              borderRadius: "4px",
              padding: theme.spacing(0.75),
            }}
          >
            <img
              src={getIcon(nodeDetails.type)}
              alt="icon"
              height={24}
              width={24}
            />
          </Box>
          <Box sx={styles.nodeDetails}>
            <Box sx={styles.nodeName}>
              <Typography
                variant="caption"
                color={theme.palette.surface80.main}
              >
                {nodeDetails.name}
              </Typography>
            </Box>
            {nodeDetails?.type !== "users" ? (
              <Box sx={styles.versionDetails}>
                <Typography
                  variant="caption"
                  color={theme.palette.surface40.main}
                >
                  {nodeDetails.versionDetails}
                </Typography>
                {nodeDetails?.cloud ? (
                  <>
                    <Divider
                      orientation="vertical"
                      sx={styles.divider}
                      color={theme.palette.surface40.main}
                    />
                    <Typography
                      variant="caption"
                      color={theme.palette.surface40.main}
                    >
                      {nodeDetails.cloud === "aws"
                        ? "AWS Lambda"
                        : nodeDetails?.cloud === "langchain"
                        ? "Langchain"
                        : nodeDetails?.cloud}
                    </Typography>
                  </>
                ) : null}
              </Box>
            ) : (
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                {nodeDetails?.appName}
              </Typography>
            )}
          </Box>
          {nodeDetails?.type === "langchain" && (
            <TextButton
              onClick={() => gotoAppDetails(nodeDetails.name)}
              endIcon={<KeyboardArrowRightIcon />}
              sx={styles.viewDetailsBtn}
            >
              View Details
            </TextButton>
          )}
        </Box>
        <Typography variant="caption" color={theme.palette.critical.main}>
          {nodeDetails.policyName}
        </Typography>
      </Box>
      <Box sx={styles.scrollableContainer}>
        {nodeDetails.type !== "users" ? (
          <>
            <Box>
              {/*<Box sx={{ ...styles.activeUsers, ...styles.paddingY }}>
                <Typography
                  sx={styles.activeUserCount}
                  color={theme.palette.surface70.main}
                >
                  {nodeDetails?.policyViolations?.length || 0}
                </Typography>
                <Typography
                  variant="caption"
                  color={theme.palette.surface70.main}
                >
                  Policy Violations
                </Typography>
              </Box>*/}
              {/*<TableContainer>
                <Table>
                  <TableHead>
                    <TableCell>Policies</TableCell>
                    <TableCell>Number of Violations</TableCell>
                  </TableHead>
                  <TableBody>
                    {nodeDetails?.policyViolations?.map((policy) => (
                      <TableRow>
                        <TableCell sx={styles.tableCell}>
                          <Typography
                            variant="caption"
                            color={theme.palette.surface60.main}
                          >
                            {policy.name}
                          </Typography>
                        </TableCell>
                        <TableCell sx={styles.barCell}>
                          <Typography
                            variant="caption"
                            color={theme.palette.surface80.main}
                          >
                            {policy.count}
                          </Typography>
                          <Bar
                            isLastBar={false}
                            xLabel={""}
                            barBackColor={theme.palette.surface20.main}
                            barFaceColor={theme.palette.lightMagenta.main}
                            rectWidth={(policy.count / 100) * 100}
                            index={3}
                            totalValue={4}
                            barLabel={""}
                            barValue={policy.count}
                            type={""}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>*/}
              {/*<Divider />*/}
            </Box>
            <Box>
              <Box sx={{ ...styles.activeUsers, ...styles.paddingY }}>
                <Typography
                  sx={styles.activeUserCount}
                  color={theme.palette.surface70.main}
                >
                  {nodeDetails.type === "langchain"
                    ? nodeDetails.sensitiveMessages.count
                    : getPolicyDetails(nodeDetails.sensitiveRecords)}
                </Typography>
                <Typography
                  variant="caption"
                  color={theme.palette.surface70.main}
                >
                  {nodeDetails.type === "langchain"
                    ? "Sensitive Messages"
                    : "Sensitive Records"}
                </Typography>
              </Box>
              {nodeDetails.type !== "langchain" ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableCell>Policies</TableCell>
                      <TableCell>Number of Violations</TableCell>
                    </TableHead>
                    <TableBody>
                      {nodeDetails?.sensitiveRecords?.map((record) => (
                        <TableRow>
                          <TableCell sx={styles.tableCell}>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {record.label}
                            </Typography>
                          </TableCell>
                          <TableCell sx={styles.barCell}>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface80.main}
                            >
                              {record.count}
                            </Typography>
                            <Bar
                              isLastBar={false}
                              xLabel={""}
                              barBackColor={theme.palette.surface20.main}
                              barFaceColor={theme.palette.pictonBlue.main}
                              rectWidth={
                                (record.count /
                                  getPolicyDetails(
                                    nodeDetails.sensitiveRecords
                                  )) *
                                100
                              }
                              index={3}
                              totalValue={getPolicyDetails(
                                nodeDetails.sensitiveRecords
                              )}
                              barLabel={""}
                              barValue={record.count}
                              type={""}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box sx={styles.flexContainer}>
                  <TopicValueBlock
                    label="Entities"
                    value={nodeDetails?.sensitiveMessages?.entities}
                  />
                  <Divider orientation="vertical" flexItem />
                  <TopicValueBlock
                    label="Topics"
                    value={nodeDetails?.sensitiveMessages?.topics}
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Box sx={styles.usersContainer}>
            <Box sx={styles.activeUsers}>
              <Typography
                color={theme.palette.surface70.main}
                sx={styles.activeUserCount}
              >
                {nodeDetails?.activeUsers}
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.surface70.main}
              >
                Active Users
              </Typography>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell>Authorized User Groups</TableCell>
                  <TableCell>Number of Users</TableCell>
                </TableHead>
                <TableBody>
                  {nodeDetails?.groups?.map((policy) => (
                    <TableRow>
                      <TableCell sx={styles.tableCell}>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {policy.name}
                        </Typography>
                      </TableCell>
                      <TableCell sx={styles.barCell}>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface80.main}
                        >
                          {policy.value}
                        </Typography>
                        <Bar
                          isLastBar={false}
                          xLabel={""}
                          barBackColor={theme.palette.surface20.main}
                          barFaceColor={theme.palette.lightMagenta.main}
                          rectWidth={
                            (policy.value /
                              (DASHBOARD_RINGS.usersWithIssues +
                                DASHBOARD_RINGS.usersWithoutIssues)) *
                            100
                          }
                          index={3}
                          totalValue={4}
                          barLabel={""}
                          barValue={policy.value}
                          type={""}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NetworkGraphCard;
