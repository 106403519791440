import React, { FC, memo } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { List } from "@mui/material";

export type Props<T> = {
  items: T[];
  onDragEnd: OnDragEndResponder;
  component: (
    item: T,
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ) => React.ReactElement;
};

export const DraggableList: any = memo(
  ({ items, onDragEnd, component }: any) => {
    return (
      <DragDropContext
        onDragEnd={onDragEnd}
        styles={{
          display: "flex",
        }}
      >
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}
              disablePadding
            >
              {items.map((item: any, index: number) => {
                console.log({ item });
                return (
                  <Draggable draggableId={item.id} index={index} key={item.id}>
                    {(provided, snapshot) =>
                      component(item, provided, snapshot)
                    }
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

DraggableList.displayName = "DraggableList";
