import { useTheme } from "@mui/material";
import { totalRetrievals } from "../../mock-data/frameworkData";
import Bar from "../common/Bar";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";
import CardWithAction from "../common/CardWithAction";
import { useHistory } from "react-router-dom";
import SolidChip from "../common/SolidChip";
import { retrievalUserDetails } from "../../mock-data/retrievalUserDetails";

const SensitiveDataCard = () => {
  const theme = useTheme();
  const history = useHistory();
  const styles = {
    cardHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    countOfLLMs: {
      display: "flex",
      alignItems: "baseline",
      gap: theme.spacing(1.25),
      width: "250px",
    },
    fontMedium: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    userGroups: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
    manrope: {
      fontFamily: "Manrope, sans-serif",
    },
    count: {
      fontSize: "20px",
      color: theme.palette.surface90.main,
    },
    chip: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
  };

  const gotoAllUsers = () => {
    history.push("/users");
  };

  const gotoUser = (name) => {
    history.push("/users/" + name);
  };

  return (
    <CardWithAction
      title="Most Active Users"
      btnAction={gotoAllUsers}
      btnTitle={"View All"}
      assignClass="dashboard-rows"
      assignId="row-3"
      children={
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell>Users</TableCell>
                <TableCell>Retrievals</TableCell>
                <TableCell align="center">Applications</TableCell>
              </TableHead>
              <TableBody>
                {retrievalUserDetails.map((dataItem: any) => (
                  <TableRow onClick={() => gotoUser(dataItem?.name)}>
                    <TableCell>
                      <Box sx={styles.userGroups}>
                        <Box sx={styles.chip}>
                          <Typography
                            variant="body2"
                            color={theme.palette.surface60.main}
                          >
                            {dataItem?.name}
                          </Typography>
                          {dataItem?.anomolous ? (
                            <SolidChip text="Anomalous User" color="#FF655B" />
                          ) : (
                            ""
                          )}
                        </Box>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface40.main}
                        >
                          {dataItem?.designation}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell sx={styles.countOfLLMs}>
                      <Typography
                        variant="caption"
                        sx={{ ...styles.count, ...styles.manrope }}
                      >
                        {dataItem?.prompts}
                      </Typography>
                      <Bar
                        isLastBar={false}
                        xLabel={""}
                        barBackColor={theme.palette.surface20.main}
                        barFaceColor={theme.palette.lightMagenta.main}
                        rectWidth={(dataItem?.prompts / totalRetrievals) * 100}
                        index={dataItem?.prompts}
                        totalValue={4}
                        barLabel={""}
                        barValue={dataItem?.prompts}
                        type={""}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="caption"
                        sx={{ ...styles.count, ...styles.manrope }}
                      >
                        {dataItem?.apps?.length}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      }
    />
  );
};

export default SensitiveDataCard;
