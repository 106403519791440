import Box from "../../components/common/Box";
import { Breadcrumbs, Link } from "../../components/common/Breadcrumbs";
import { Tabs } from "../../components/common/Tabs";
import DocumentRetrievals from "./DocumentRetrievals";
import Typography from "../../components/common/Typography";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { useParams } from "react-router";
import { documents } from "../../mock-data/documents";
import Divider from "../../components/common/Divider";
import { getIcon } from "../../utils/commonUtils";
import TitlePath from "./TitlePath";
import TextButton from "../../components/common/TextButton";
import JiraIcon from "../../assets/img/jira.svg";
import GroupsIcon from "../../assets/img/groupsIcon.svg";
import LabelAndValue from "./LabelAndValue";
import SolidChip from "../../components/common/SolidChip";

export const confidentialityTagColorMapping = {
  Default: "#474A62",
  Internal: "#307ABB",
  Public: "#87C06D",
  Confidential: "#D27541",
  "Highly Confidential": "#6750A3",
  Restricted: "#D5A139",
};

const DocumentDetailsPage = () => {
  const [document, setDocument] = useState<any>(null);
  const theme = useTheme();
  const params = useParams();

  const styles = {
    docName: {
      fontSize: "20px",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(5),
    },
    activeLink: { color: theme.palette.surface80.main },
    blueLink: {
      color: theme.palette.primaryBlue.main,
    },
    breadCrumbLink: {
      fontSize: theme.typography.caption,
    },
    docTitle: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    pageHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    docDetails: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(5),
      backgroundColor: theme.palette.surface5.main,
    },
    cardFirstRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    jiraBtn: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      textTransform: "none",
      paddingY: theme.spacing(1),
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
    },
    docGroupsInfo: {
      display: "grid",
      gridTemplateColumns: "1fr 1px 1fr 1px 1fr",
      justifyItems: "center",
      gridGap: theme.spacing(2),
    },
    font13: {
      fontSize: "13px",
    },
    group: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    groupTitle: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "baseline",
    },
    row: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    groupCount: {
      fontFamily: "Manrope, sans-serif",
      fontSize: "24px",
    },
    divider: {
      backgroundColor: theme.palette.surface20.main,
    },
    labelSubtext: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    labelGraph: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    groupFirstChild: {
      justifySelf: "flex-start",
    },
    groupList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };

  useEffect(() => {
    const { id } = params;
    const docDetails = documents.find((doc) => doc.name.includes(id));
    setDocument(docDetails);
  }, []);

  return (
    <Box sx={styles.card}>
      <Box sx={styles.pageHeader}>
        <Breadcrumbs sx={styles.breadCrumbLink}>
          <Link underline="hover" color="inherit" href="/" sx={styles.blueLink}>
            Home
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href="/documents"
            sx={styles.blueLink}
          >
            Documents
          </Link>
          <Typography variant="caption" sx={styles.activeLink}>
            {document?.name}
          </Typography>
        </Breadcrumbs>
        {document && (
          <Box sx={styles.docDetails}>
            <Box sx={styles.cardFirstRow}>
              <Box sx={styles.docTitle}>
                {document?.type && (
                  <img src={getIcon(document?.type)} alt="type" height={24} />
                )}
                <Box sx={styles.labelSubtext}>
                  <Typography
                    color={theme.palette.surface80.main}
                    sx={{
                      ...styles.docName,
                      textWrap: "nowrap",
                    }}
                  >
                    {document?.name}
                  </Typography>
                  <Box sx={styles.docTitle}>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface50.main}
                    >
                      {document?.size} |
                    </Typography>
                    <SolidChip
                      text={document?.confidentialityTag}
                      color={
                        confidentialityTagColorMapping[
                          document?.confidentialityTag
                        ]
                      }
                      textColor={theme.palette.surface100.main}
                    />
                  </Box>
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem sx={styles.divider} />
              <TitlePath type={document.source} subText={document.path} />
              <Divider orientation="vertical" flexItem sx={styles.divider} />
              <TitlePath
                type={document?.database?.name}
                subText={`Last Refreshed : ${document?.database?.updatedAt}`}
              />
              <TextButton
                startIcon={<img src={JiraIcon} alt="jira" />}
                sx={styles.jiraBtn}
              >
                Create Ticket
              </TextButton>
            </Box>
            <Box sx={styles.docGroupsInfo}>
              <Box sx={{ ...styles.group, ...styles.groupFirstChild }}>
                <Box sx={styles.groupTitle}>
                  <Typography
                    sx={styles.groupCount}
                    color={theme.palette.surface80.main}
                  >
                    {document?.authorizedGroups?.length}
                  </Typography>
                  <Typography
                    variant="body2"
                    color={theme.palette.surface80.main}
                  >
                    Groups with Access
                  </Typography>
                </Box>
                <Box sx={styles.groupList}>
                  {document?.authorizedGroups?.map((group) => (
                    <Box sx={styles.row}>
                      <img src={GroupsIcon} width="16px" />
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.surface70.main}
                      >
                        {group}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem sx={styles.divider} />
              <Box sx={styles.group}>
                <Box sx={styles.groupTitle}>
                  <Typography
                    sx={styles.groupCount}
                    color={theme.palette.surface80.main}
                  >
                    {document?.harmfulLabels?.length}
                  </Typography>
                  <Typography
                    variant="body2"
                    color={theme.palette.surface80.main}
                  >
                    Harmful Labels
                  </Typography>
                </Box>
                <Box sx={styles.labelGraph}>
                  <LabelAndValue labels={document?.harmfulLabels} />
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem sx={styles.divider} />
              <Box sx={styles.group}>
                <Box sx={styles.groupTitle}>
                  <Typography
                    sx={styles.groupCount}
                    color={theme.palette.surface80.main}
                  >
                    {document?.sensitiveLabels?.length}
                  </Typography>
                  <Typography
                    variant="body2"
                    color={theme.palette.surface80.main}
                  >
                    Sensitive Labels
                  </Typography>
                </Box>
                <Box sx={styles.labelGraph}>
                  <LabelAndValue labels={document?.sensitiveLabels} />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <DocumentRetrievals document={document} />
      </Box>
    </Box>
  );
};

export default DocumentDetailsPage;
