import { IconButton, styled, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Box from "../common/Box";
import Drawer from "../common/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Dialog, DialogContent } from "../common/Dialog";
import Typography from "../common/Typography";
import Button from "../common/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import GenerateAPIKey from "./GenerateAPIKey";
import NoSearchIcon from "../../assets/img/No_Search_illustration.svg";
import {
  fetchAllAPIKeysSlice,
  addNewAPIKeySlice,
  deleteAPIKeySlice,
} from "../../redux/slices/apiKeySlice";
import { useDispatch } from "react-redux";
import { getUserId } from "../../utils/SessionHelper";
import { getDifferenceInDays, getMMMDDYYYY } from "../../utils/commonUtils";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import TextButton from "../common/TextButton";
import Divider from "../common/Divider";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RevokeAPI from "./RevokeAPI";
import NoData from "../common/NoData";
import PackageDownload from "../packageDownload/PackageDownload";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import Loader from "../common/Loader";
import { TextField } from "../common/TextField";
import Tooltip from "../common/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { PackageInstallationInfo } from "../packageDownload/PackageInstallationInfo";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ReactComponent as CopyIcon } from "../../assets/img/copy.svg";
import { mockAPIKeys } from "../../mock-data/apiKeyData";

const APIConfigDrawer = ({ open, onClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch<any>();
  const [apiKeys, setAPIKeys] = useState<
    Array<{
      _id: string;
      name: string;
      metadata: { createdAt: string };
      lastUsedAt: string;
      apiKey: string;
    }>
  >([]);
  const [isFetchingAPIKeys, setIsFetchingAPIKeys] = useState(false);
  const [isAddKey, setIsAddKey] = useState(false);
  const [showAPIKey, setShowAPIKey] = useState<Array<string>>([]);
  const [showDeleteKeyDialog, setShowDeleteKeyDialog] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const openPopover = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const styles = {
    dialog: {
      display: "flex",
      flexDirection: "column",
      ".MuiPaper-root": {
        background: theme.palette.surface0.main,
      },
    },
    dialogHeader: {
      display: "flex",
      padding: "20px 20px 0px 8px",
      alignItems: "center",
    },
    backIcon: {
      color: theme.palette.surface50.main,
      fontSize: theme.spacing(3),
      width: theme.spacing(3),
    },
    keyHeader: {
      fontSize: "13px",
    },
    dialogContent: {
      display: "flex",
      gap: theme.spacing(2),
      height: "100%",
    },
    apiListBox: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      width: "75%",
      backgroundColor: theme.palette.surface10.main,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      overflow: "auto",
    },
    apiTableHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    apiHeaderText: {
      width: "60%",
      height: "100%",
      display: "flex",
      gap: theme.spacing(0.5),
    },
    packageBox: {
      width: "25%",
      backgroundColor: theme.palette.surface10.main,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      overflow: "auto",
    },
    noDataContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    apiKeyIcon: {
      fontSize: theme.typography.body1,
    },
    apiKeyIconColor: { color: theme.palette.surface80.main },
    apiKeyCell: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    key: {
      minWidth: theme.spacing(36.5),
      maxWidth: theme.spacing(36.5),
      wordWrap: "break-word",
    },
    apiKeyActions: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    tableContainer: {
      height: "100%",
    },
    keyTextField: {
      minWidth: theme.spacing(25),
      maxWidth: theme.spacing(25),
      "& .MuiInputBase-root": {
        paddingLeft: theme.spacing(0.5),
        fontSize: theme.spacing(1.5),
        ".MuiInputAdornment-root": {
          display: "none",
        },
        "&:hover": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(255, 255, 255, 0.23)",
          },
        },
        "&.Mui-focused": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(255, 255, 255, 0.23)",
          },
        },
      },
      input: {
        padding: theme.spacing(0.5),
      },
    },
    infoIcon: {
      color: theme.palette.surface40.main,
      cursor: "pointer",
    },
    drawerHeading: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
    },
  };

  const getAllAPIKeys = async () => {
    setIsFetchingAPIKeys(true);
    setAPIKeys(mockAPIKeys);
    const res = await dispatch(fetchAllAPIKeysSlice());
    if (res.payload) {
      setAPIKeys(res.payload);
    }
    setIsFetchingAPIKeys(false);
  };

  const addAPIKey = async (apiKeyDetails) => {
    const payload = {
      ...apiKeyDetails,
      userId: getUserId(),
    };
    const res = await dispatch(addNewAPIKeySlice(payload));
    if (res.payload) {
      Toaster(TOASTER_TYPES.SUCCESS, "API Key Added!");
      getAllAPIKeys();
    }
  };

  const getAPIKeyString = (apiKey) => {
    const keyValue = apiKey?.apiKey;
    if (getAPIKeyVisibilityFlag(apiKey?.name)) {
      return keyValue;
    } else {
      return keyValue.replace(
        keyValue.substr(3, keyValue.length - 7),
        keyValue.substr(4, keyValue.length - 7).replace(/./g, "*")
      );
    }
  };

  const toggleAPIKeyVisibility = (apiKeyName: string) => {
    const newState = showAPIKey.includes(apiKeyName)
      ? showAPIKey.filter((key) => key !== apiKeyName)
      : [...showAPIKey, apiKeyName];
    setShowAPIKey(newState);
  };

  const getAPIKeyVisibilityFlag = (apiKeyName: string) => {
    return showAPIKey.includes(apiKeyName);
  };

  const deleteAPIKey = async () => {
    const res = await dispatch(deleteAPIKeySlice(showDeleteKeyDialog));
    if (res?.payload) {
      setShowDeleteKeyDialog("");
      getAllAPIKeys();
      Toaster(TOASTER_TYPES.SUCCESS, "API Key Deleted!");
    }
  };

  useEffect(() => {
    getAllAPIKeys();
  }, []);

  const copyAPIKey = (apiKey) => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      Toaster(TOASTER_TYPES.SUCCESS, "API Key copied to clipboard!");
    }
  };

  const infoDescription =
    "Your secret API Keys are listed below. Do not share API Keys with others, or expose it in the browser or code.";

  return (
    <Dialog fullScreen open={open} sx={styles.dialog}>
      <Box sx={styles.dialogHeader}>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon sx={styles.backIcon} />
        </IconButton>
        <Box sx={styles.drawerHeading}>
          <Typography variant="subtitle1" color={theme.palette.surface50.main}>
            API Key & Packages
          </Typography>
          <TextButton startIcon={<HelpOutlineIcon />} onClick={openPopover}>
            Install Instructions
          </TextButton>
          <PackageInstallationInfo
            open={isPopoverOpen}
            anchorEl={anchorEl}
            handleClose={handleClose}
          />
        </Box>
      </Box>
      <DialogContent>
        <Box sx={styles.dialogContent}>
          <Box sx={styles.apiListBox}>
            <Box sx={styles.apiTableHeader}>
              <Box sx={styles.apiHeaderText}>
                <Typography
                  sx={styles.keyHeader}
                  color={theme.palette.surface80.main}
                >
                  API Key
                </Typography>
                {/* <Typography
                  variant="caption"
                  color={theme.palette.surface50.main}>
                  Your secret API Keys are listed below. Do not share API Keys
                  with others, or expose it in the browser or code.
                </Typography> */}
                <Tooltip placement="right" arrow title={infoDescription}>
                  <InfoOutlinedIcon fontSize="inherit" sx={styles.infoIcon} />
                </Tooltip>
              </Box>
              <Box>
                <Button
                  onClick={() => setIsAddKey(true)}
                  variant="outlined"
                  size="small"
                >
                  Generate New Key
                </Button>
              </Box>
            </Box>
            <TableContainer sx={styles.tableContainer}>
              <Table>
                <TableHead>
                  <TableCell>Name</TableCell>
                  <TableCell>Key</TableCell>
                  <TableCell>Used By</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Last Used</TableCell>
                  <TableCell></TableCell>
                </TableHead>
                {!isFetchingAPIKeys ? (
                  <TableBody>
                    {apiKeys.length > 0 ? (
                      apiKeys.map((apiKey) => (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {apiKey?.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box sx={styles.apiKeyCell}>
                              {/* <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}
                                sx={styles.key}>
                                {getAPIKeyString(apiKey)}
                              </Typography> */}
                              <TextField
                                value={getAPIKeyString(apiKey)}
                                sx={styles.keyTextField}
                              />
                              <Box sx={styles.apiKeyActions}>
                                <TextButton
                                  onClick={() =>
                                    toggleAPIKeyVisibility(apiKey?.name)
                                  }
                                >
                                  {getAPIKeyVisibilityFlag(apiKey?.name) ? (
                                    <VisibilityOffOutlinedIcon
                                      sx={{
                                        ...styles.apiKeyIcon,
                                        ...styles.apiKeyIconColor,
                                      }}
                                    />
                                  ) : (
                                    <VisibilityOutlinedIcon
                                      sx={{
                                        ...styles.apiKeyIcon,
                                        ...styles.apiKeyIconColor,
                                      }}
                                    />
                                  )}
                                </TextButton>
                                <Divider orientation="vertical" flexItem />
                                <TextButton
                                  onClick={() => copyAPIKey(apiKey?.apiKey)}
                                >
                                  <CopyIcon />
                                </TextButton>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {getMMMDDYYYY(apiKey?.metadata?.createdAt)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {getDifferenceInDays(
                                new Date(apiKey?.lastUsedAt),
                                new Date()
                              )}{" "}
                              Days Ago
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box sx={styles.apiKeyActions}>
                              {/*<TextButton>
                                <EditOutlinedIcon sx={styles.apiKeyIcon} />
                              </TextButton>
                              <Divider orientation="vertical" flexItem />*/}
                              <TextButton
                                onClick={() =>
                                  setShowDeleteKeyDialog(apiKey?._id)
                                }
                              >
                                <DeleteOutlineOutlinedIcon
                                  sx={styles.apiKeyIcon}
                                />
                              </TextButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableCell colSpan={6} borderBottom={false}>
                        <Box sx={styles.noDataContainer}>
                          <NoData customText="No API Keys Found." />
                        </Box>
                      </TableCell>
                    )}
                  </TableBody>
                ) : (
                  <TableCell colSpan={6} borderBottom={false}>
                    <Loader height="300px" />
                  </TableCell>
                )}
              </Table>
            </TableContainer>
          </Box>
          <Box sx={styles.packageBox}>
            <PackageDownload />
          </Box>
        </Box>
      </DialogContent>
      <GenerateAPIKey
        open={isAddKey}
        onClose={() => {
          setIsAddKey(false);
        }}
        onAdd={(apiKeyDetails) => {
          addAPIKey(apiKeyDetails);
          setIsAddKey(false);
        }}
      />
      {showDeleteKeyDialog && (
        <RevokeAPI
          open={showDeleteKeyDialog}
          onClose={() => {
            setShowDeleteKeyDialog("");
          }}
          onDelete={() => deleteAPIKey()}
        />
      )}
    </Dialog>
  );
};

export default APIConfigDrawer;
