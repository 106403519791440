import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { PolicyViolationDetailsCard } from "../LLMModel/PolicyViolationDetailsCard";
import { FrameworkSummaryCard } from "./FrameworkSummaryCard";
import { FrameworkInstanceDetails } from "./FrameworkInstanceDetails";
import data from "../../../mock-data/llmFrameworkPageData.json";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { FrameworkSensitiveData } from "./FrameworkSensitiveData";
import TranningDataOrchestratorGraph from "../../charts/llm-charts/training-data-orchestrator/TrainingDataOrchestratorGraph";
import { trainingDataList } from "../../shadow-llm/training_data";
import ManageTagsModal from "../../../components/modal/manage-tags-modal/ManageTagsModal";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerId } from "../../../utils/SessionHelper";
import { listDataStoreTagsAsync } from "../../../redux/slices/dataStoreSlice";
import Loader from "../../common/Loader";
import { fetchFrameworkDetailsSlice } from "../../../redux/slices/dashboardSlice";
import NoData from "../../common/NoData";
import { Breadcrumbs, Link } from "../../common/Breadcrumbs";
import LLMTabs from "./LLMTabs";
import PromptSummaryPanel from "./PromptSummaryPanel";
import LLMFrameworkDetails from "./LLMFrameworkDetails";
import { TabPanel } from "../../common/Tabs";
import { LangchainGraph } from "../graph/LangchainGraph";
import { ChainGraph } from "../graph/ChainGraph";
import { LangchainGraphNode } from "../graph/LangchainGraphNode";
import { CollapseIconComponent } from "../../common/CollapseIcon";
import LangchainIcon from "../../../assets/img/langchain.svg";
import ChromaIcon from "../../../assets/img/chromaIcon.svg";
import PostgresIcon from "../../../assets/img/postgres.svg";
import { frameworkPromptSummaryData } from "../../../mock-data/frameworkPromptMessagesData";
import AWSIcon from "../../../assets/img/awsIcon.svg";
import { getIcon, mergeArrayByProperty } from "../../../utils/commonUtils";
import { DataSourceTab } from "./DataSourceTab";
import { datasources } from "../../../mock-data/appDatasource";
import { applicationDetails } from "../../../mock-data/applicationDetails";

export const LLMFrameworkPage = ({ setPath }) => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const promptTimeFilter = useSelector(
    (state: any) => state.dashboard.promptFilter
  );
  const ref = useRef<any>(null);
  const [frameworkData, setFrameworkData] = useState<any>({});
  const [isManageTagsModal, setIsManageTagsModal] = useState(false);
  const objectId = params.id;
  const [width, setWidth] = useState<any>(0);
  const [tags, setTags] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("overview");
  const [nodeHeight, setNodeHeight] = useState(0);
  const [collapsibleTreeData, setCollapsibleTreeData] = useState<any>({});
  const [frameworkJSON, setFrameworkJSON] = useState<any>({
    name: "",
    promptSummaryData: [],
    policyViolations: [],
  });
  let entity = "";

  const datastoreId = "7cd5c0fa-4321-4f89-bd9b-e9979b823c30";

  const styles = {
    loader: {
      height: "100vh",
      width: "100%",
      backgroundColor: theme.palette.surface10.main,
    },
    pageLayout: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    langchainFramework: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      height: "100%",
    },
    breadCrumbLink: {
      fontSize: theme.typography.caption,
    },
    activeLink: { color: theme.palette.surface80.main },
    blueLink: {
      color: theme.palette.primaryBlue.main,
    },
    langchainHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    langchainTitle: {
      display: "flex",
      gap: theme.spacing(1.25),
      alignItems: "center",
    },
    langchainDetailsCard: {
      display: "flex",
      gap: theme.spacing(4),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      height: "auto",
      transition: "all 0.5s",
    },
    tabLabel: {
      paddingX: theme.spacing(1),
    },
    noDataFound: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
  };

  const getFrameworkDetails = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
      id: objectId,
    };
    const resp = await dispatch(fetchFrameworkDetailsSlice(payload));
    if (resp?.payload) {
      const frameworkInfo =
        applicationDetails?.find((item) => item?.id === objectId) ||
        resp?.payload;
      setFrameworkData(frameworkInfo);
      entity =
        frameworkInfo?.frameworkSummary?.name === "HealthCareApp-1"
          ? "PHI - MRN"
          : frameworkInfo?.frameworkSummary?.name === "AcmeMortgageApp-1"
          ? "PII - SSN, PII - Credit Card"
          : "";
      const chainData =
        datasources.find(
          (data) => data.name === frameworkInfo?.frameworkSummary?.name
        )?.chains || frameworkInfo?.chains;
      const structuredTreeData = buildCollapsibleGraphData(chainData);
      setCollapsibleTreeData(structuredTreeData);
      const jsonData = frameworkPromptSummaryData.find(
        (data) => data.name === frameworkInfo?.frameworkSummary?.name
      );
      setFrameworkJSON(jsonData);
    } else {
      setFrameworkData({});
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (objectId) {
      const filteredData = data?.frameworkPageData.find(
        (item) => item?.id === objectId
      );
      setFrameworkData(filteredData?.data);
    }
  }, [objectId]);

  useEffect(() => {
    getFrameworkDetails();
    const widthValue: any = ref?.current?.offsetWidth;
    setWidth(widthValue);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getWidth);
    setPath(window.location.pathname);
    // return () => {
    //   removeSelectedDataFlow();
    // };
  }, []);

  const getWidth = () => {
    setWidth(ref?.current?.clientWidth);
  };

  const refreshDataStoreTags = async () => {
    const res = await dispatch(
      listDataStoreTagsAsync({
        customerId: getCustomerId(),
        cloudAccountId: frameworkData?.cloudAccountId || "",
        datastoreId: datastoreId,
      })
    );
    let tagList: any = [];
    if (res && res.payload && !res.payload.err) {
      // Adding sensitive tag
      if (res.payload?.customerSensTag) {
        tagList.push({
          value: res?.payload?.customerSensTag,
          label: res?.payload?.customerSensTag,
          type: "sensitive",
        });
      }
      if (res.payload?.customerTags) {
        // Adding custom tag
        res.payload.customerTags.map((tag) => {
          if (
            tag.toLowerCase() === "us_access_only" ||
            tag.includes("us access only") ||
            tag.includes("us-access-only")
          ) {
            // props.setIsUSOnlyAccess(true);
          }

          tagList.push({
            value: tag,
            label: tag,
            type: "custom",
          });
        });
      }
    }
    setTags(tagList);
  };

  const promptStats = frameworkData?.promptStats;

  const frameworkTabs = [
    {
      value: "overview",
      label: (
        <Typography variant="body2" sx={styles.tabLabel}>
          Overview
        </Typography>
      ),
    },
    {
      value: "datasource",
      label: (
        <Typography variant="body2" sx={styles.tabLabel}>
          Safe Loader
        </Typography>
      ),
    },
    {
      value: "promptSummary",
      label: (
        <Typography variant="body2" sx={styles.tabLabel}>
          Safe Retrieval
        </Typography>
      ),
    },
    //{
    //  value: "policyViolations",
    //  label: (
    //    <Typography variant="body2" sx={styles.tabLabel}>
    //      Policy Violations
    //    </Typography>
    //  ),
    //},
  ];

  const buildCollapsibleGraphData = (unstructuredData) => {
    if (unstructuredData?.length > 0) {
      let graphData = {};
      unstructuredData?.map((item) => {
        graphData = {
          name: item.name,
          height: 70,
          width: 200,
          isNode: false,
          nodeDepth: 600,
          icon: <CollapseIconComponent id={item.name} />,
          node: (
            <LangchainGraphNode
              icon={null}
              title={item.name}
              variant="main"
              isNode={false}
            />
          ),
          children:
            item?.vectorDbs?.length > 0
              ? getLangchainChildren(item?.vectorDbs, item)
              : [],
        };
      });
      return graphData;
    }
    return {};
  };

  const constructChainData = (unstructuredData) => {
    if (Object.keys(unstructuredData)?.length > 0) {
      let graphData = {
        name: unstructuredData?.name,
        version: unstructuredData?.version,
        height: 70,
        width: 200,
        isNode: true,
        nodeDepth: 200,
        packageInfo: unstructuredData?.packageInfo,
        node: (
          <LangchainGraphNode
            id="lineage-graph-node"
            icon={getIcon(unstructuredData?.name)}
            title={unstructuredData?.name}
            description={unstructuredData?.version}
            isNode={unstructuredData?.packageInfo ? true : false}
          />
        ),
        icon:
          unstructuredData?.bucket?.length > 0 ? (
            <CollapseIconComponent id={unstructuredData?.name} />
          ) : (
            ""
          ),
        children:
          unstructuredData?.bucket?.length > 0
            ? getVectorDbChildren(unstructuredData?.bucket)
            : [],
      };
      return graphData;
    }
    return {};
  };

  const addNullObject = (array) => {
    let newArray: any = [];
    for (let i = 0; i < array?.length - 1; i++) {
      newArray.push({});
    }
    return [...newArray, ...array];
  };

  const getLangchainChildren = (array, item) => {
    let collapsibleNodeHeight = 0;
    let chainNumber = 0;
    let newArray = array?.map((db) => {
      let height =
        db?.bucket?.length > 0
          ? 200 *
            (db?.bucket?.length === 1
              ? db?.bucket?.length + 1
              : db?.bucket?.length)
          : 300;
      if (collapsibleNodeHeight > height) {
        collapsibleNodeHeight = nodeHeight;
      } else {
        collapsibleNodeHeight = height;
      }
      chainNumber = chainNumber + 1;
      return {
        name: db?.name,
        width: db?.bucket?.length > 0 ? 600 : 228,
        height:
          db?.bucket?.length > 0
            ? 200 *
              (db?.bucket?.length === 1
                ? db?.bucket?.length + 1
                : db?.bucket?.length)
            : 300,
        isNode: false,
        node: (
          <ChainGraph
            className={`Chain-${chainNumber}`}
            isChild={true}
            chainData={constructChainData(db)}
          />
        ),
        chainData: {
          ...constructChainData(db),
          modelDetail: item?.model,
        },
      };
    });
    setNodeHeight(collapsibleNodeHeight);
    return addNullObject(newArray);
  };

  const getVectorDbChildren = (array) => {
    let newArray = array?.map((item) => {
      return {
        name: item?.name,
        height: 70,
        width: 200,
        isNode: false,
        node: (
          <LangchainGraphNode
            icon={getIcon(item?.type)}
            title={
              item?.name === "Local Files (PDF)" ? "AWS S3 (PDF)" : item?.name
            }
            // description={item?.sensitiveData}
            description={entity}
            isNode={false}
          />
        ),
      };
    });

    return addNullObject(newArray);
  };

  return (
    <>
      {isLoading ? (
        <Box sx={styles.loader}>
          <Loader />
        </Box>
      ) : Object.keys(frameworkData)?.length > 0 ? (
        <Box sx={styles.langchainFramework}>
          <Breadcrumbs sx={styles.breadCrumbLink}>
            <Link
              underline="hover"
              color="inherit"
              href="/"
              sx={styles.blueLink}
            >
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/"
              sx={styles.blueLink}
            >
              Applications
            </Link>
            <Typography variant="caption" sx={styles.activeLink}>
              {frameworkData?.frameworkSummary?.name}
            </Typography>
          </Breadcrumbs>
          <LLMFrameworkDetails
            frameworkData={{
              ...frameworkData,
              frameworkSummary:
                datasources.find(
                  (data) => data.name === frameworkData?.frameworkSummary?.name
                )?.summary || frameworkData?.frameworkSummary,
            }}
            appDescription={frameworkJSON?.description}
          />
          <LLMTabs
            tabs={frameworkTabs}
            activeTab={selectedTab}
            onTabChange={(event, newValue) => setSelectedTab(newValue)}
          />
          <TabPanel value={selectedTab} index="overview" key="overview">
            <LangchainGraph
              treeData={collapsibleTreeData}
              nodeHeight={nodeHeight}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index="datasource" key="datasource">
            <DataSourceTab
              tabData={frameworkJSON?.loaderData}
              messagesData={
                frameworkJSON?.snippetData || frameworkJSON?.promptSummaryData
              }
              frameworkData={frameworkData}
            />
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index="promptSummary"
            key="promptSummary"
          >
            <PromptSummaryPanel
              frameworkData={frameworkData}
              messagesData={mergeArrayByProperty(
                frameworkJSON?.promptSummaryData
              )}
              totalPrompts={
                frameworkData?.promptStats[promptTimeFilter]?.promptCount
              }
              graphData={frameworkData?.promptTrends}
            />
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index="policyViolations"
            key="policyViolations"
          >
            <PolicyViolationDetailsCard
              policyViolationDetails={frameworkJSON?.policyViolations}
              ticketIntegration={false}
            />
          </TabPanel>
        </Box>
      ) : (
        <Box sx={styles.noDataFound}>
          <NoData customText="No data found." />
        </Box>
      )}
    </>
  );
};
