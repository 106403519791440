import moment from "moment";

export const policyTableData = [
  {
    policyName: "Identity Policy_2_UserGroups_Finance_01",
    type: "identity",
    idp: {
      label: "Azure Active Directory",
      value: "Azure Active Directory",
      path: "LDAP://OU=SecurityGroups,OU=Departments,OU=Company,DC=corp,DC=company,DC=com",
      groups: [
        "us_support_group@acmecorp.com",
        "underwriter_group@acmecorp.com",
        "us_intern_group@acmecorp.com",
        "sales_group@acmecorp.com",
        "customer_care@acmecorp.com",
      ],
    },
    config: {
      SemanticGuardrailConfig: {
        topics: ["Financial", "Harmful"],
        entities: ["Finance"],
      },
      Exceptions: false,
      Groups: ["us_intern_group@acmecorp.com"],
      ConfidentialityTags: "Public to Confidential",
    },
    addedOn: moment().subtract(1, "days").format("YYYY-MM-DD") + " 12:11:48",
    owner: "John Doe",
    violations: 0,
  },
];

export const topicsTableData = [
  {
    topic: "Medical Advice",
    description: "Providing medical advice or information on ..",
    createdOn: "2023-10-21  05:12:34",
  },
  {
    topic: "Financial Reports",
    description: "Reports or information related to financ ..",
    createdOn: "2023-10-24 15:32:18",
  },
];

export const entityTableData = [
  {
    entity: "Employee ID",
    createdOn: "2023-10-22  12:11:48",
  },
  {
    entity: "Company Confidential",
    createdOn: "2023-10-23  04:32:32",
  },
];
