import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import Checkbox from "../../components/common/Checkbox";
import { useEffect, useState } from "react";
import { classificationLabelsList } from "../../mock-data/classificationLabelsData";
import { Radio } from "../../components/common/Radio";

const LabelSelector = ({ checked, selected, label, onChange }: any) => {
  const theme = useTheme();
  const styles = {
    flexCol: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.25),
    },
    flex: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    bar: {
      height: "8px",
      width: "110px",
      backgroundColor: selected
        ? theme.palette.primaryBlue.main
        : theme.palette.surface30.main,
    },
    radio: {
      "&.MuiRadio-root": {
        padding: 0,
      },
    },
  };
  return (
    <Box sx={styles.flexCol}>
      <Box sx={styles.flex}>
        <Radio
          label={label}
          checked={checked}
          onChange={onChange}
          sx={styles.radio}
        />
        <Typography variant="body2">{label}</Typography>
      </Box>
      <Box sx={styles.bar}></Box>
    </Box>
  );
};

const MapClassificationLabels = ({
  selectedClassification,
  setSelectedClassification,
  labelsList,
}) => {
  const [labels, setLabels] = useState([]);
  const theme = useTheme();
  const styles = {
    header: {
      paddingX: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.surface20.main}`,
    },
    labelsList: {
      display: "flex",
      gap: theme.spacing(1),
      padding: theme.spacing(2),
    },
    subText: {
      display: "flex",
      justifyContent: "space-between",
    },
  };

  useEffect(() => {
    const allLabels: any = [...labelsList];
    setLabels(allLabels.reverse());
  }, [labelsList]);

  const isLabelSelected = (currIndex) => {
    return selectedClassification >= currIndex;
  };

  return (
    <Box>
      <Box sx={styles.header}>
        <Typography color={theme.palette.surface50.main} variant="body2">
          Users groups will be granted access upto the selected Classification
          Tag.
        </Typography>
      </Box>
      <Box sx={styles.labelsList}>
        {labels?.map((label: any, index) => (
          <LabelSelector
            checked={selectedClassification === index}
            selected={isLabelSelected(index)}
            label={label?.name}
            onChange={() => {
              if (selectedClassification === index)
                setSelectedClassification(-1);
              else setSelectedClassification(index);
            }}
          />
        ))}
      </Box>
      {/*<Box sx={styles.subText}>
        <Typography variant="body2" color={theme.palette.surface50.main}>
          Lowest Priority
        </Typography>
        <Typography variant="body2" color={theme.palette.surface50.main}>
          Highest Priority
        </Typography>
      </Box>*/}
    </Box>
  );
};

export default MapClassificationLabels;
