import { useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";
import TextButton from "./TextButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const CardWithAction = ({
  title,
  subText,
  btnTitle,
  btnAction,
  children,
  assignClass,
  assignId,
}: {
  title: any;
  subText?: any;
  btnTitle?: any;
  btnAction?: any;
  children: any;
  assignClass?: any;
  assignId?: any;
}) => {
  const theme = useTheme();
  const styles = {
    card: {
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.surface10.main,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.25),
      width: "100%",
      height: "100%",
    },
    cardHeader: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: theme.spacing(2),
      gap: theme.spacing(1),
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    fontMedium: {
      fontWeight: theme.typography.fontWeightRegular,
    },
  };

  return (
    <Box sx={styles.card} className={assignClass} id={assignId}>
      <Box sx={styles.cardHeader}>
        <Box sx={styles.title}>
          <Typography
            color={theme.palette.surface80.main}
            sx={styles.cardTitle}
          >
            {title}
          </Typography>
          {btnTitle && (
            <TextButton
              endIcon={<KeyboardArrowRightIcon />}
              onClick={btnAction}
            >
              {btnTitle}
            </TextButton>
          )}
        </Box>
        {subText && (
          <Typography
            color={theme.palette.surface80.main}
            variant="caption"
            sx={styles.fontMedium}
          >
            {subText}
          </Typography>
        )}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default CardWithAction;
