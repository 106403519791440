import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Box from "../../common/Box";
import MessageDetails from "../MessageDetails";
import SensitiveMessageTable from "../SensitiveMessageTable";
import SnippetDetails from "./SnippetDetails";

const SensitiveMessagesDetails = ({
  messageData,
  frameworkData,
  selectedUser,
  selectedFilter,
  appName,
}) => {
  const [selectedSensitiveLabel, setSelectedSensitiveLabel] = useState("");
  const [messages, setMessages] = useState([]);
  const theme = useTheme();
  const styles = {
    summaryContent: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      columnGap: theme.spacing(3),
    },
  };

  useEffect(() => {
    console.log({ messageData });
    setSelectedSensitiveLabel(messageData[0]?.sensitiveLabel);
  }, [messageData]);

  useEffect(() => {
    const res =
      messageData.find(
        (promptData) => promptData.sensitiveLabel === selectedSensitiveLabel
      )?.messages || [];
    setMessages(res);
  }, [selectedSensitiveLabel, selectedUser, selectedFilter]);

  return (
    <Box sx={styles.summaryContent}>
      <SensitiveMessageTable
        selectedUser={selectedUser}
        sensitiveData={messageData}
        onRowClick={(label) => {
          setSelectedSensitiveLabel(label);
        }}
        selectedSensitiveLabel={selectedSensitiveLabel}
        appName={appName}
        selectedFilter={selectedFilter}
      />
      <MessageDetails
        sensitiveData={messageData}
        messagesList={messages}
        selectedSensitiveLabel={selectedSensitiveLabel}
        frameworkData={frameworkData}
        selectedUser={selectedUser}
        selectedFilter={selectedFilter}
      />
    </Box>
  );
};

export default SensitiveMessagesDetails;
