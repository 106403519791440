import { useEffect, useState } from "react";
import Box from "../../components/common/Box";
import { useTheme } from "@mui/material";
import { Breadcrumbs, Link } from "../../components/common/Breadcrumbs";
import Typography from "../../components/common/Typography";
import Search from "../../components/common/Search";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../components/common/Table";
import { documents } from "../../mock-data/documents";
import { capitalizeFirstLetter, getIcon } from "../../utils/commonUtils";
import HarmfulBadge from "../../components/common/HarmfulBadge";
import { useHistory } from "react-router-dom";
import NoData from "../../components/common/NoData";

const DocumentsListingPage = () => {
  const [documentList, setDocumentList] = useState<any>([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const theme = useTheme();
  const history = useHistory();
  const styles = {
    page: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    activeLink: { color: theme.palette.surface80.main },
    blueLink: {
      color: theme.palette.primaryBlue.main,
    },
    breadCrumbLink: {
      fontSize: theme.typography.caption,
    },
    users: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    tableTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
    tableContainer: {
      backgroundColor: theme.palette.surface10.main,
    },
    font13: {
      fontSize: "13px",
    },
    userApps: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      paddingY: theme.spacing(1),
    },
    datastore: {
      display: "flex",
      gap: theme.spacing(0.5),
      alignItems: "center",
    },
    alignBaseline: {
      alignItems: "baseline",
    },
  };
  useEffect(() => {
    setDocumentList(documents);
  }, []);

  const gotoDocDetails = (name) => {
    history.push(`/documents/${name}`);
  };

  useEffect(() => {
    const filteredList = documents.filter((doc) =>
      doc.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setDocumentList(filteredList);
  }, [searchKeyword]);

  return (
    <Box sx={styles.page}>
      <Breadcrumbs sx={styles.breadCrumbLink}>
        <Link underline="hover" color="inherit" href="/" sx={styles.blueLink}>
          Home
        </Link>
        <Typography variant="caption" sx={styles.activeLink}>
          Documents
        </Typography>
      </Breadcrumbs>

      <Box sx={styles.users}>
        <Box sx={styles.tableTitle}>
          <Typography>All Documents ({documentList?.length})</Typography>
          <Search
            placeholder="Search By Name"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onClear={() => setSearchKeyword("")}
            onKeyDown={(e) => {}}
          />
        </Box>
        <TableContainer sx={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableCell>Document</TableCell>
              <TableCell>Document Source</TableCell>
              <TableCell>Snippets with Sensitive Content</TableCell>
              <TableCell>Retrievals with Sensitive Data</TableCell>
            </TableHead>
            <TableBody sx={{ minWidth: "250px" }}>
              {documentList?.length ? (
                documentList.map((document) => (
                  <TableRow onClick={() => gotoDocDetails(document?.name)}>
                    <TableCell>
                      <Box>
                        <Typography
                          sx={styles.font13}
                          color={theme.palette.surface70.main}
                        >
                          {document?.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={styles.datastore}>
                        <img
                          src={getIcon(document?.source)}
                          alt="source"
                          height={18}
                        />
                        <Typography
                          sx={styles.font13}
                          color={theme.palette.surface80.main}
                        >
                          {capitalizeFirstLetter(document?.source)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{ ...styles.datastore, ...styles.alignBaseline }}
                      >
                        <Typography
                          variant="body2"
                          color={theme.palette.surface80.main}
                        >
                          {document?.sensitiveData?.value}
                        </Typography>
                        <Typography
                          sx={styles.font13}
                          color={theme.palette.surface50.main}
                        >
                          {`/ ${document?.sensitiveData?.outOf}`}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{ ...styles.datastore, ...styles.alignBaseline }}
                      >
                        {document?.sensitiveContent?.value ? (
                          <HarmfulBadge />
                        ) : null}
                        <Typography
                          variant="body2"
                          color={theme.palette.surface80.main}
                        >
                          {document?.sensitiveContent?.value}
                        </Typography>
                        <Typography
                          sx={styles.font13}
                          color={theme.palette.surface50.main}
                        >
                          {`/ ${document?.sensitiveContent?.outOf}`}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <NoData customText="No Documents" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DocumentsListingPage;
