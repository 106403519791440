import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "../../common/Box";
import Checkbox from "../../common/Checkbox";
import Divider from "../../common/Divider";
import Search from "../../common/Search";
import { Select } from "../../common/Select";
import Typography from "../../common/Typography";
import {
  promptSession,
  promptTimestampFilter,
} from "../../constants/constants";
import PromptLineGraph from "./PromptLineGraph";
import { setPromptTimeFilter } from "../../../redux/slices/dashboardSlice";
import SensitiveMessagesDetails from "./SensitiveMessagesDetails";
import TreeMap from "../../common/TreeMap";
import { treeMapData } from "../../../mock-data/treeMapData";
import { Tab, Tabs } from "../../common/Tabs";
import RetrievalUserDetails from "./RetrievalUserDetails";
import {
  fileDetails,
  retrievalUserDetails,
} from "../../../mock-data/retrievalUserDetails";
import { frameworkPromptSummaryData } from "../../../mock-data/frameworkPromptMessagesData";
import { frameworkData as frameworkDetailsList } from "../../../mock-data/frameworkData";

const PromptSummaryPanel = ({
  frameworkData = {},
  messagesData = [],
  totalPrompts = 0,
  graphData = [],
}: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [trendLinesData, setTrendLinesData] = useState([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedFilter, setSelectedFilter] = useState<any>("user");
  const [filterValue, setFilterValue] = useState([
    "sensitivePromptCount",
    "sensitiveContextCount",
    "sensitiveResponseCount",
  ]);
  const [treeMapView, setTreeMapView] = useState(1);
  const promptFilter = useSelector(
    (state: any) => state.dashboard.promptFilter
  );

  const sensitiveMessages = frameworkDetailsList.find(
    (app) => app?.name === frameworkData?.frameworkSummary?.name
  )?.sensitiveMessages;

  const categories = [
    {
      name: "sensitivePromptCount",
      label: "Prompts",
      color: theme.palette.peach.main,
    },
    {
      name: "sensitiveContextCount",
      label: "Context",
      color: theme.palette.laurelGreen.main,
    },
    {
      name: "sensitiveResponseCount",
      label: "Responses",
      color: theme.palette.paleBlue.main,
    },
  ];

  const formatTrendData = (data) => {
    let result: any = [];
    if (data?.length > 0) {
      data?.forEach((item: any) => {
        for (const [key, value] of Object.entries(item)) {
          if (
            key !== "at" &&
            filterValue.includes(key) &&
            categories.find((category) => category.name === key)
          ) {
            result.push({
              category: key,
              issues: Number(value),
              date: item?.at,
            });
          }
        }
      });
    }
    result.sort((obj1, obj2) => +new Date(obj1.date) - +new Date(obj2.date));
    return result;
  };

  const styles = {
    card: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    treeMapContainer: {
      width: "100%",
    },
    cardHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    messageBlock: {
      display: "flex",
      flexDirection: "column",
    },
    sensitiveMessages: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    messageCount: {
      fontSize: "24px",
    },
    formControls: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    checkboxLabel: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    checkbox: {
      "& .MuiSvgIcon-root": { fontSize: "20px" },
    },
    promptCheckbox: {
      color: theme.palette.peach.main,
    },
    responseCheckbox: {
      color: theme.palette.paleBlue.main,
    },
    contextCheckbox: {
      color: theme.palette.laurelGreen.main,
    },
    select: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    graphContainer: {},
    promptSummary: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    summaryHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    sortByText: {
      fontSize: "13px",
    },
    messageDetailsHeader: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    sortByBox: {
      display: "flex",
      alignItems: "end",
      gap: theme.spacing(1.5),
    },
  };

  useEffect(() => {
    const result = formatTrendData(graphData[promptFilter]) || [];
    setTrendLinesData(result);
  }, [graphData, promptFilter, filterValue]);

  const isChecked = (keyword) => filterValue.includes(keyword);

  const handleChecked = (keyword) => {
    const filterOptions = isChecked(keyword)
      ? filterValue.filter((filterOption) => filterOption !== keyword)
      : [...filterValue, keyword];
    setFilterValue(filterOptions);
  };

  const getUserDetails = () => {
    if (selectedUser) {
      if (selectedFilter === "user") {
        const userLabels = JSON.parse(
          localStorage.getItem("restrictedLabels") || "{}"
        );
        const appName = frameworkData?.frameworkSummary?.name;
        const storedLabels = userLabels[selectedUser]
          ? userLabels[selectedUser]?.frameworkRestrictedLabels[appName]
          : [];
        const user = retrievalUserDetails?.find(
          (user) => user?.name === selectedUser
        );
        return {
          ...user,
          restrictedLabels: storedLabels?.length
            ? storedLabels
            : user?.frameworkRestrictedLabels[appName],
        };
      } else {
        const shortFileName = selectedUser?.split("/")?.at(-1);
        if (shortFileName) {
          return fileDetails.find((file) =>
            file.fileName.includes(shortFileName)
          );
        }
        return {};
      }
    }
    return {};
  };

  const appName =
    frameworkData && frameworkData?.frameworkSummary
      ? frameworkData?.frameworkSummary?.name
      : "";
  const treeData = treeMapData?.find((data) => data.appName === appName);

  const treeMapViews = [
    {
      value: 1,
      label: "Users",
      key: "Users",
    },
    {
      value: 0,
      label: "Document",
      key: "Document",
    },
  ];

  const treeMapList =
    treeMapView === 0 ? treeData?.mapData || [] : treeData?.usersMapData || [];

  const getMaxValue = () => {
    if (treeMapList?.length >= 4) {
      return treeMapList[3]?.value || 0;
    }
    return treeMapList[1]?.value || 0;
  };

  const getMessagesData = () => {
    let msgList = messagesData;
    if (selectedUser && selectedFilter === "user") {
      msgList = messagesData
        ?.map((msg) => ({
          ...msg,
          messages: msg?.messages?.filter(
            (item) => item?.retrievedBy === selectedUser
          ),
        }))
        ?.filter((message) => message?.messages?.length > 0);
    } else if (selectedUser && selectedFilter === "file") {
      const fileName = selectedUser?.split("/")?.at(-1);
      console.log("fileName", fileName);
      msgList = messagesData
        ?.map((msg) => ({
          ...msg,
          messages: msg?.messages?.filter((item) =>
            item?.sourceFile?.includes(fileName)
          ),
        }))
        ?.filter((message) => message?.messages?.length > 0);
    }
    return msgList;
  };

  return (
    <Box sx={styles.card}>
      <Box sx={styles.cardHeader}>
        <Box sx={styles.messageBlock}>
          <Box sx={styles.sensitiveMessages}>
            <Typography color={theme.palette.surface70.main} variant="body2">
              Retrieval Count
            </Typography>
            <Typography
              color={theme.palette.critical.main}
              sx={styles.messageCount}
            >
              {sensitiveMessages}
            </Typography>
          </Box>
          <Typography
            variant="caption"
            color={theme.palette.surface50.main}
          >{`Total Messages ${totalPrompts}`}</Typography>
        </Box>
        {/*<Box sx={styles.formControls}>
          <Select
            minWidth={100}
            options={promptTimestampFilter}
            value={promptFilter}
            sx={styles.select}
            variant="standard"
            onChange={async (event: any) =>
              await dispatch(setPromptTimeFilter(event.target.value))
            }
          />
          <Divider
            orientation="vertical"
            sx={{
              height: "16px",
            }}
          />
          <Box sx={styles.checkboxLabel}>
            <Checkbox
              label="all"
              checked={filterValue === "all"}
              onChange={(event) => {
                setFilterValue("");
                if (event.target.checked) {
                  setFilterValue("all");
                } else {
                  setFilterValue("");
                }
                console.log(event.target.checked);
              }}
              sx={styles.checkbox}
            />
            <Typography variant="caption" color={theme.palette.surface40.main}>
              All
            </Typography>
          </Box>
          <Box sx={styles.checkboxLabel}>
            <Checkbox
              label="prompts"
              checked={isChecked(promptSession.prompt)}
              onChange={() => handleChecked(promptSession.prompt)}
              sx={{ ...styles.checkbox, ...styles.promptCheckbox }}
            />
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Prompts
            </Typography>
          </Box>
          <Box sx={styles.checkboxLabel}>
            <Checkbox
              label="context"
              checked={isChecked(promptSession.context)}
              onChange={() => handleChecked(promptSession.context)}
              sx={{ ...styles.checkbox, ...styles.contextCheckbox }}
            />
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Context
            </Typography>
          </Box>
          <Box sx={styles.checkboxLabel}>
            <Checkbox
              label="responses"
              checked={isChecked(promptSession.response)}
              onChange={() => handleChecked(promptSession.response)}
              sx={{ ...styles.checkbox, ...styles.responseCheckbox }}
            />
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Responses
            </Typography>
          </Box>
        </Box>*/}
      </Box>
      <Box sx={styles.graphContainer}>
        {/*<PromptLineGraph
          categories={categories}
          graphData={trendLinesData}
          ticks={3}
          svgHeight={200}
        />*/}
        <Box sx={styles.treeMapContainer}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Box sx={{ fontSize: "12px" }}>View By</Box>
            <Tabs
              value={treeMapView}
              onChange={(event, newValue) => {
                setTreeMapView(newValue);
                setSelectedUser(null);
                setSelectedFilter(newValue === 1 ? "user" : "file");
              }}
            >
              {treeMapViews?.map((item) => (
                <Tab value={item.value} label={item.label} key={item.value} />
              ))}
            </Tabs>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            <TreeMap
              setSelectedUser={setSelectedUser}
              setSelectedFilter={setSelectedFilter}
              selectedFilter={selectedFilter}
              selectedUser={selectedUser}
              data={treeMapList}
              maxTwoValue={getMaxValue()}
              selectedTab={treeMapView}
              appName={appName}
            />
            {selectedUser && (
              <RetrievalUserDetails
                userDetails={getUserDetails()}
                appName={frameworkData?.frameworkSummary?.name || ""}
                selectedFilter={selectedFilter}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Divider orientation="horizontal" />
      <Box sx={styles.promptSummary}>
        <Box sx={styles.summaryHeader}>
          {!selectedUser && (
            <Typography variant="body2">Retrievals by Labels</Typography>
          )}
          {/*<Box sx={styles.messageDetailsHeader}>
            <Box sx={styles.sortByBox}>
              <Typography sx={styles.sortByText}>Sort by</Typography>
              <Select />
            </Box>
            <Search
              placeholder="Search By Keyword"
              value={""}
              onChange={(e) => {}}
              onClear={() => {}}
              onKeyDown={(e) => {}}
            />
          </Box>*/}
        </Box>
        <SensitiveMessagesDetails
          messageData={getMessagesData()}
          frameworkData={frameworkData}
          selectedUser={selectedUser}
          selectedFilter={selectedFilter}
          appName={appName}
        />
      </Box>
    </Box>
  );
};

export default PromptSummaryPanel;
