import { useTheme } from "@mui/material";
import Box from "./Box";
import Chip from "./Chip";
import S3Icon from "../../assets/img/AWS_S3.svg";
import ChromaIcon from "../../assets/img/chromaIcon.svg";
import PostgresIcon from "../../assets/img/postgres.svg";
import GoogleDriveIcon from "../../assets/img/google-drive.png";
import PDFIcon from "../../assets/img/pdf.svg";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Typography from "./Typography";
import Tooltip from "./Tooltip";
import SharePointIcon from "../../assets/img/sharepoint.png";
import SlackIcon from "../../assets/img/slack.png";
import { getIcon } from "../../utils/commonUtils";

const Node = ({ icon, label, type, isViolation }) => {
  const theme = useTheme();
  const styles = {
    chipContainer: {
      position: "relative",
    },
    chip: {
      "&.MuiChip-root": {
        borderRadius: theme.spacing(0.5),
        border: `1px solid ${theme.palette.surface20.main}`,
        backgroundColor: theme.palette.surface10.main,
        padding: "8px 12px",
      },
      "& .MuiChip-avatar": {
        width: "18px",
        height: "18px",
      },
    },
    redBadge: {
      backgroundColor: theme.palette.critical.main,
      border: "2px solid black",
      borderRadius: "50%",
      height: "12px",
      width: "12px",
      position: "absolute",
      top: -3,
      right: -3,
    },
  };

  const getNodeLabel = () => {
    switch (type) {
      case "s3":
        return "S3 Bucket";
      case "pdf":
        return label?.split("/")?.at(-1) || label;
      case "md":
        return label?.split("/")?.at(-1) || label;
      case "google":
        return "Google Drive";
      case "sharepoint":
        return "SharePoint Loader";
      case "slack":
        return "Slack API Loader";
      case "pinecone":
        return "Pinecone";
      case "qdrant":
        return "Qdrant";
      case "vespa":
        return "Vespa";
      default:
        return label;
    }
  };

  return (
    <Tooltip title={label} placement="top">
      <div>
        <Box sx={styles.chipContainer}>
          <Chip
            avatar={icon}
            label={
              <Typography
                variant="caption"
                color={theme.palette.surface70.main}
              >
                {getNodeLabel()}
              </Typography>
            }
            variant="outlined"
            sx={styles.chip}
          />
          {isViolation && <Box sx={styles.redBadge}></Box>}
        </Box>
      </div>
    </Tooltip>
  );
};

const Link = ({ linkColor }) => {
  const theme = useTheme();
  const styles = {
    linkContainer: {
      width: "34px",
      display: "flex",
      alignItems: "center",
      position: "relative",
      justifyContent: "center",
    },
    link: {
      backgroundColor: linkColor,
      width: "100%",
      height: "1px",
    },
    arrow: {
      height: "14px",
      width: "14px",
      color: linkColor,
      position: "absolute",
    },
  };
  return (
    <Box sx={styles.linkContainer}>
      <Box sx={styles.link}></Box>
      <PlayArrowIcon sx={styles.arrow} />
    </Box>
  );
};

const NodeChain = ({ nodes = [], linkColor }) => {
  const theme = useTheme();
  const styles = {
    chainContainer: {
      display: "flex",
      maxWidth: "100%",
      overflow: "auto",
    },
  };

  const getImageForNode = (type: string) => {
    switch (type) {
      case "s3":
        return S3Icon;
      case "chroma":
        return ChromaIcon;
      case "postgres":
        return PostgresIcon;
      case "pdf":
        return PDFIcon;
      case "google":
        return GoogleDriveIcon;
      case "sharepoint":
        return SharePointIcon;
      case "slack":
        return SlackIcon;
      default:
        return "";
    }
  };

  return (
    <Box sx={styles.chainContainer}>
      {nodes?.map(
        (
          node: { name: string; type: string; isViolation: boolean },
          index: number
        ) => {
          const imgURL = getIcon(node.type);
          return (
            <>
              <Node
                label={node.name}
                type={node.type}
                icon={imgURL && <img src={imgURL} width="14px" />}
                isViolation={node.isViolation}
              />
              {index < nodes.length - 1 && <Link linkColor={linkColor} />}
            </>
          );
        }
      )}
    </Box>
  );
};

export default NodeChain;
