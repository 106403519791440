import { useEffect, useState } from "react";
// import Logo from "../../assets/img/new-theme/logo.svg";
import Logo from "../../assets/img/daxa-assets/daxa-icon.svg";
import { ReactComponent as DaxaTitle } from "../../assets/img/daxa-assets/daxa-title.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Sidebar.scss";
import { ReactComponent as Home } from "../../assets/img/new-theme/sidebar/home.svg";
import { ReactComponent as Data } from "../../assets/img/new-theme/sidebar/data.svg";
import { ReactComponent as Issues } from "../../assets/img/new-theme/sidebar/issues.svg";
import { ReactComponent as Cloud } from "../../assets/img/new-theme/sidebar/cloud.svg";
import { ReactComponent as Reports } from "../../assets/img/new-theme/sidebar/report.svg";
import { ReactComponent as Accounts } from "../../assets/img/new-theme/sidebar/accounts.svg";
import { ReactComponent as Integrations } from "../../assets/img/new-theme/sidebar/integrations.svg";
import { ReactComponent as Policy } from "../../assets/img/new-theme/sidebar/policy.svg";
import { ReactComponent as UserManagement } from "../../assets/img/new-theme/sidebar/user_management.svg";
import { ReactComponent as LeftArrowIcon } from "../../assets/img/new-theme/left-circle-arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/img/new-theme/right-circle-arrow.svg";
import {
  expandSidebar,
  minimizeSidebar,
} from "../../redux/slices/dashboardSlice";
import "reactjs-popup/dist/index.css";
import { useLocation } from "react-router-dom";
import CustomTooltip from "../custom-tooltip/CustomTooltip";
import { useDispatch } from "react-redux";
import Box from "../common/Box";

const Sidebar = ({
  setTab,
  expandedSidebar,
  setExpandedSidebar,
  tab,
  path,
  setPath,
}) => {
  const [admin, setAdmin] = useState(false);
  const location = useLocation();
  const accountsAvailable = useSelector(
    (state) => state.account.accountAvailable
  );
  const dispatch = useDispatch();

  const firstAccountStatus = useSelector(
    (state) => state.dashboard.firstAccountStatus
  );

  const toggleSidebar = () => {
    if (expandedSidebar) {
      dispatch(minimizeSidebar());
    } else {
      dispatch(expandSidebar());
    }
  };

  const riskStatCompleted =
    firstAccountStatus && firstAccountStatus.first_acct_risk_complete;

  // useEffect(() => {
  //   if (path === "/checklist") {
  //     setTab(3);
  //   } else if (
  //     path.includes("settings") &&
  //     localStorage.getItem("role") === "ADMIN"
  //   ) {
  //     setAdmin(true);
  //   } else {
  //     setAdmin(false);
  //   }
  // }, [path]);

  return (
    <div
      id="cd-sidebar"
      className={`${
        expandedSidebar ? "expanded-sidebar" : "collapsed-sidebar"
      }`}
    >
      <div className="d-flex flex-column justify-content-between h-100 overflow-scroll">
        <div className={`${expandedSidebar ? "cd-logo" : "cd-logo"} px-4`}>
          <Link to={accountsAvailable ? "/" : "#"}>
            <div className="d-flex align-items-center">
              <div>
                <img src={Logo} alt="Logo" height={32} />
              </div>

              <div
                className={`ml-3 daxa-logo-title ${
                  expandedSidebar ? "visible" : "hidden"
                }`}
              >
                <DaxaTitle className="w-16" height="100%" />
              </div>
            </div>
          </Link>
        </div>

        <div className="d-flex flex-column justify-content-between h-100 px-3">
          <Box className="arrow-icon">
            {expandedSidebar && (
              <LeftArrowIcon
                className="cursor-pointer "
                onClick={() => toggleSidebar()}
              />
            )}
            {!expandedSidebar && (
              <RightArrowIcon
                className="cursor-pointer"
                onClick={() => toggleSidebar()}
              />
            )}
          </Box>
          <div>
            <CustomTooltip
              component={
                <div className="mt-5 mb-2">
                  <Link to="/">
                    <div
                      className={`${
                        location.pathname === "/"
                          ? expandedSidebar
                            ? "sidebar-menu-selected-extended"
                            : "sidebar-menu-selected"
                          : expandedSidebar
                          ? "sidebar-menu-extended"
                          : "sidebar-menu"
                      }`}
                    >
                      <Home />

                      {expandedSidebar ? (
                        <span className="sidebar-menu-title">Home</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </Link>
                </div>
              }
              tooltipTitle="Home"
            />
            {/*<CustomTooltip
              component={
                <div className="mb-2">
                  <Link to={accountsAvailable ? "/datastore" : "#"}>
                    <div
                      className={`${
                        location.pathname === "/datastore"
                          ? expandedSidebar
                            ? "sidebar-menu-selected-extended"
                            : "sidebar-menu-selected"
                          : expandedSidebar
                          ? "sidebar-menu-extended"
                          : "sidebar-menu"
                      }`}
                    >
                      {" "}
                      <Data />
                      {expandedSidebar ? (
                        <span className="sidebar-menu-title">Data</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </Link>
                </div>
              }
              tooltipTitle="Data Stores"
            />*/}
            {/*<CustomTooltip
              component={
                <div className="mb-2">
                  <Link to={accountsAvailable ? "/vulnerabilities" : "#"}>
                    <div
                      className={`${
                        location.pathname === "/vulnerabilities"
                          ? expandedSidebar
                            ? "sidebar-menu-selected-extended"
                            : "sidebar-menu-selected"
                          : expandedSidebar
                          ? "sidebar-menu-extended"
                          : "sidebar-menu"
                      }`}
                    >
                      <Issues />
                      {expandedSidebar ? (
                        <span className="sidebar-menu-title">Issues</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </Link>
                </div>
              }
              tooltipTitle="Issues"
            />*/}
            {/*<CustomTooltip
              component={
                <div className="mb-2">
                  <Link to={accountsAvailable ? "/cloud/assets" : "#"}>
                    <div
                      className={`${
                        location.pathname === "/cloud/assets"
                          ? expandedSidebar
                            ? "sidebar-menu-selected-extended"
                            : "sidebar-menu-selected"
                          : expandedSidebar
                          ? "sidebar-menu-extended"
                          : "sidebar-menu"
                      }`}>
                      <Cloud />
                      {expandedSidebar ? (
                        <span className="sidebar-menu-title">Cloud</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </Link>
                </div>
              }
              tooltipTitle="Cloud"
            />*/}
            <CustomTooltip
              component={
                <div className="mb-2">
                  <Link to="/settings/policy-settings">
                    <div
                      className={`${
                        location.pathname === "/settings/policy-settings"
                          ? expandedSidebar
                            ? "sidebar-menu-selected-extended"
                            : "sidebar-menu-selected"
                          : expandedSidebar
                          ? "sidebar-menu-extended"
                          : "sidebar-menu"
                      }`}
                    >
                      <Policy />
                      {expandedSidebar ? (
                        <span className="sidebar-menu-title">Policy</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </Link>
                </div>
              }
              tooltipTitle="Policy Settings"
            />
            {/*<CustomTooltip
              component={
                <div className="mb-2">
                  <Link to="/account">
                    <div
                      className={`${
                        location.pathname === "/account"
                          ? expandedSidebar
                            ? "sidebar-menu-selected-extended"
                            : "sidebar-menu-selected"
                          : expandedSidebar
                          ? "sidebar-menu-extended"
                          : "sidebar-menu"
                      }`}
                    >
                      {" "}
                      <Accounts />
                      {expandedSidebar ? (
                        <span className="sidebar-menu-title">Accounts</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </Link>
                </div>
              }
              tooltipTitle="Accounts"
            />*/}
            <CustomTooltip
              component={
                <div className="mb-2">
                  <Link to={accountsAvailable ? "/reports" : "#"}>
                    <div
                      className={`${
                        location.pathname === "/reports"
                          ? expandedSidebar
                            ? "sidebar-menu-selected-extended"
                            : "sidebar-menu-selected"
                          : expandedSidebar
                          ? "sidebar-menu-extended"
                          : "sidebar-menu"
                      }`}
                    >
                      <Reports />
                      {expandedSidebar ? (
                        <span className="sidebar-menu-title">Reports</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </Link>
                </div>
              }
              tooltipTitle="Reports"
            />
            <CustomTooltip
              component={
                <div className="mb-2">
                  <Link to="/settings/integrations">
                    <div
                      className={`${
                        location.pathname === "/settings/integrations"
                          ? expandedSidebar
                            ? "sidebar-menu-selected-extended"
                            : "sidebar-menu-selected"
                          : expandedSidebar
                          ? "sidebar-menu-extended"
                          : "sidebar-menu"
                      }`}
                    >
                      <Integrations />
                      {expandedSidebar ? (
                        <span className="sidebar-menu-title">Integrations</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </Link>
                </div>
              }
              tooltipTitle="Integrations"
            />
            <CustomTooltip
              component={
                <div className="mb-2">
                  <Link to="/settings/admin">
                    <div
                      className={`${
                        location.pathname === "/settings/admin"
                          ? expandedSidebar
                            ? "sidebar-menu-selected-extended"
                            : "sidebar-menu-selected"
                          : expandedSidebar
                          ? "sidebar-menu-extended"
                          : "sidebar-menu"
                      }`}
                    >
                      {" "}
                      <UserManagement />
                      {expandedSidebar ? (
                        <span className="sidebar-menu-title">Users</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </Link>
                </div>
              }
              tooltipTitle=" User Management"
            />
          </div>
          <div></div>
        </div>

        <div className=""></div>
      </div>
    </div>
  );
};

export default Sidebar;
