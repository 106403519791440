export const mockAPIKeys = [
  {
    metadata: {
      createdAt: "2024-10-07T16:44:26.473Z",
      modifiedAt: "2024-10-07T16:44:26.473Z",
      customerId: "c84ca491-ab60-4906-8573-bff6d766475b",
    },
    _id: "67040fea861fc69569f5f7c4",
    id: "39629adb-3123-4c49-a462-3d16e2e78cc7",
    name: "pebblo-key-1",
    apiKey: "klMXoGtJEp84aKiHN8khx5kkp1iID8tB54nkirRp",
    createdBy: "shreyas@clouddefense.io",
    lastUsedAt: "2024-10-09T16:44:26.472Z",
    __v: 0,
  },
  {
    metadata: {
      createdAt: "2024-09-06T14:13:07.073Z",
      modifiedAt: "2024-09-06T14:13:07.073Z",
      customerId: "c84ca491-ab60-4906-8573-bff6d766475b",
    },
    _id: "6570817321567d40d34f8483",
    id: "001a0952-5ed4-46e3-aadc-9ef3189cc9eb",
    name: "System Generated",
    apiKey: "ZWrJhhmnmf3gdPoAtAPWq3w5XhLUZuGe5lNuusoD",
    createdBy: "Default",
    lastUsedAt: "2024-10-09T14:13:07.072Z",
    __v: 0,
  },
];
