import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import Button from "../../components/common/Button";
import JiraIcon from "../../assets/img/jira.svg";
import ContextDetails from "./ContextDetails";
import PromptPolicyDetails from "./PromptPolicyDetails";
import TextButton from "../../components/common/TextButton";

const PromptDetailsCard = ({ promptDetails, onCreateTicket }) => {
  const theme = useTheme();
  const styles = {
    card: {
      borderLeft: "1px solid #292D40",
      width: "100%",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      gap: theme.spacing(4),
      padding: theme.spacing(2),
      borderBottom: "1px solid #292D40",
      alignItems: "center",
    },
    jiraBtn: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      textTransform: "none",
      paddingY: theme.spacing(1),
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
      whiteSpace: "nowrap",
    },
    details: {
      height: "100%",
    },
    prompt: {
      width: "100%",
      display: "flex",
      height: "100%",
    },
    policy: {},
  };

  return (
    <Box sx={styles.card}>
      <Box sx={styles.header}>
        <Typography color={theme.palette.surface80.main} variant="body2">
          {promptDetails?.prompt}
        </Typography>
        <TextButton
          startIcon={<img src={JiraIcon} alt="jira" />}
          sx={styles.jiraBtn}
          onClick={onCreateTicket}
        >
          Create Ticket
        </TextButton>
      </Box>
      <Box sx={styles.details}>
        <Box sx={styles.prompt}>
          <ContextDetails
            initialContext={promptDetails?.context}
            finalContext={promptDetails?.finalContext}
            response={promptDetails?.response}
          />
          <PromptPolicyDetails appDetails={promptDetails} />
        </Box>
      </Box>
    </Box>
  );
};

export default PromptDetailsCard;
