import Box from "../../common/Box";
interface LangchainGraphNodeProp {
  icon: any;
  title: string;
  id?: string;
  description?: string;
  helperText?: string;
  hasChild?: boolean;
  isActive?: boolean;
  isCollapsed?: boolean;
  variant?: string;
  isNode: boolean;
  frameworkData?: any;
}

export const LangchainGraphNode = ({
  icon,
  title,
  id,
  description,
  helperText,
  hasChild = false,
  isActive = false,
  isCollapsed = false,
  variant = "primary",
  isNode = false,
  frameworkData,
}: LangchainGraphNodeProp) => {
  const backgroundColor =
    variant === "main" ? "rgb(201 107 249 / 30%)" : "none";

  const styles = {
    node: {
      display: "flex",
      //gap: "10px",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: icon ? "flex-start" : "center",
      borderRadius: "4px",
      paddingLeft: "12px",
      backgroundColor: backgroundColor,
      cursor: isNode ? "pointer" : "default",
      ":hover": {
        background: isNode && "#191C2E",
      },
    },
    iconWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // background: "#3A3D50",
      height: "46px",
      width: "46px",
      borderRadius: "50%",
      padding: "12px",
      flexShrink: 0,
    },
    body: {
      overflow: "hidden",
    },
    title: {
      fontSize: "13px",
      color: "#fff",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    description: {
      fontSize: "12px",
      color: "#B5B8CA",
    },
    helperText: {
      color: "#EA6262",
      fontSize: "12px",
    },
  };

  return (
    <Box id={id} sx={styles.node}>
      {icon ? (
        <Box sx={styles.iconWrapper}>
          <img alt="Icon" src={icon} height={28} width={28} />
        </Box>
      ) : null}
      <Box sx={styles.body}>
        <Box title={title} sx={styles.title}>
          {title}
        </Box>
        {description ? <Box style={styles.description}>{description}</Box> : ""}
        {/* <Box style={styles.helperText}>{helperText}</Box> */}
      </Box>
    </Box>
  );
};
