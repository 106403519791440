import { useTheme } from "@mui/material";
import Box from "../common/Box";
import Typography from "../common/Typography";

const HeroStat = ({ label, value, outOf, suffix, classList }) => {
  const theme = useTheme();
  const styles = {
    card: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    flex: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "baseline",
    },
    value: {
      fontSize: "32px",
      fontWeight: 500,
      color: theme.palette.surface80.main,
    },
    manrope: {
      fontFamily: "Manrope",
    },
  };
  return (
    <Box sx={styles.card} className={`dashboard-nav ${classList}`}>
      <Typography
        variant="body2"
        color={theme.palette.surface60.main}
        sx={styles.manrope}
      >
        {label}
      </Typography>
      <Box sx={styles.flex}>
        <Typography sx={styles.value}>{value}</Typography>
        <Typography variant="body2" color={theme.palette.surface80.main}>
          / {outOf} {suffix}
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroStat;
