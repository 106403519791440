import { useTheme } from "@mui/material";
import Box from "./Box";
import { Tab, Tabs } from "./Tabs";
import Typography from "./Typography";

const CustomTab = ({
  value,
  color = "",
  label,
  count,
  selectedValue,
  outOf,
}) => {
  const theme = useTheme();
  const styles = {
    tab: {
      backgroundColor:
        selectedValue === value ? theme.palette.surface10.main : "transparent",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      gap: theme.spacing(1),
      padding: theme.spacing(2),
      borderRadius: "4px 4px 0px 0px",
    },
    rowBadge: (color: string) => ({
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: color,
    }),
    valueText: {
      fontFamily: "Manrope, sans-serif",
      fontSize: "44px",
    },
    font13: {
      fontSize: "13px",
    },
    tabLabel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    value: {
      display: "flex",
      alignItems: "baseline",
      gap: theme.spacing(1),
    },
    outOfText: {
      fontSize: "24px",
    },
  };
  return (
    <Box sx={styles.tab}>
      <Box sx={styles.value}>
        <Typography color={theme.palette.surface90.main} sx={styles.valueText}>
          {count}
        </Typography>
        {outOf ? <Typography sx={styles.outOfText}>/ {outOf}</Typography> : ""}
      </Box>
      <Box sx={styles.tabLabel}>
        {color && <Box sx={styles.rowBadge(color)}></Box>}
        <Typography color={theme.palette.surface90.main} sx={styles.font13}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomTab;
