import { useTheme } from "@mui/material";
import Box from "../common/Box";
import CardWithAction from "../common/CardWithAction";
import CommonTreeMap from "../common/CommonTreeMap";
import { activeUserGroupsByRetrievals } from "../../mock-data/activeUserGroupsByRetrievals";

const UserGroupsByRetrievals = () => {
  const theme = useTheme();
  const styles = {
    card: {
      paddingX: theme.spacing(2),
    },
  };

  const colorScale = (label) => "#3F466D";

  return (
    <CardWithAction
      title="Most Active User Groups by Retrievals"
      children={
        <Box sx={styles.card}>
          <CommonTreeMap
            index="activeUserGroups"
            colorScale={colorScale}
            data={activeUserGroupsByRetrievals}
            graphHeight={200}
          />
        </Box>
      }
    />
  );
};

export default UserGroupsByRetrievals;
