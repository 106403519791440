import { useTheme } from "@mui/material";
import Box from "../common/Box";
import CardWithAction from "../common/CardWithAction";
import CommonTreeMap from "../common/CommonTreeMap";
import { snippetsByLabels, sources } from "../../mock-data/snippetsByLabels";
import { TagColorMapping } from "../../mock-data/tagColorMapping";
import LegendWithColor from "../common/LegendWithColor";
import Typography from "../common/Typography";

const SnippetsByLabels = () => {
  const theme = useTheme();
  const styles = {
    card: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    graphContainer: {
      paddingX: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.25),
    },
    legends: {
      display: "flex",
      gap: theme.spacing(2),
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
  return (
    <Box
      sx={{
        width: "30%",
      }}
    >
      {" "}
      <CardWithAction
        title="Snippets by Labels"
        children={
          <Box sx={styles.card}>
            <Box sx={styles.graphContainer}>
              <CommonTreeMap
                index="snippetByLabels"
                colorScale={(label) => TagColorMapping[label]}
                data={snippetsByLabels}
                graphHeight={200}
              />
              <Box sx={styles.legends}>
                {Object.keys(TagColorMapping)?.map((tag) => (
                  <LegendWithColor color={TagColorMapping[tag]} label={tag} />
                ))}
              </Box>
            </Box>
            <Box sx={styles.graphContainer}>
              <Typography
                color={theme.palette.surface80.main}
                sx={styles.cardTitle}
              >
                Documents by Sources
              </Typography>
              <Box
                sx={{
                  paddingBottom: theme.spacing(2),
                }}
              >
                <CommonTreeMap
                  index="sources"
                  colorScale={() => "#3F466D"}
                  data={sources}
                  graphHeight={150}
                />
              </Box>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default SnippetsByLabels;
