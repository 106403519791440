import { useState } from "react";
import Box from "../../common/Box";
import DatastoreTicketModal from "../datastore-ticket-modal/DatastoreTicketModal";
import { useTheme } from "@mui/material";
import Typography from "../../common/Typography";
import { TextField } from "../../common/TextField";

const CreateTicketModal = ({ open, onClose, title, body }) => {
  const theme = useTheme();
  const styles = {
    modalBody: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    modalContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };
  return (
    <>
      {open && (
        <DatastoreTicketModal handleModal={onClose}>
          <Box sx={styles.modalBody}>
            <Box sx={styles.modalContent}>
              <Typography
                variant="caption"
                color={theme.palette.surface60.main}
              >
                Ticket Name: <span className="mandatory-icon">*</span>
              </Typography>
              <TextField fullWidth value={title} />
            </Box>
            <Box sx={styles.modalContent}>
              <Typography
                variant="caption"
                color={theme.palette.surface60.main}
              >
                Description:
              </Typography>
              <TextField
                fullWidth
                multiline
                minRows={10}
                rows={10}
                value={body}
              />
            </Box>
          </Box>
        </DatastoreTicketModal>
      )}
    </>
  );
};

export default CreateTicketModal;
