import { useTheme } from "@mui/material";
import Box from "./Box";

const SpectrumGraph = ({ data, height, width }) => {
  const theme = useTheme();
  const styles = {
    bar: {
      display: "flex",
      width: `${width}px`,
      height: `${height}px`,
    },
    segment: (label) => ({
      width: `${(label.value / 100) * width}%`,
      backgroundColor: label.color,
    }),
  };
  return (
    <Box sx={styles.bar}>
      {data && data?.length
        ? data.map((label, index) => (
            <Box key={label.label} sx={styles.segment(label)}></Box>
          ))
        : null}
    </Box>
  );
};

export default SpectrumGraph;
