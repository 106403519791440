import { IconButton, useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import { Select } from "../../components/common/Select";
import { useEffect, useMemo, useState } from "react";
import Typography from "../../components/common/Typography";
import Search from "../../components/common/Search";
import Checkbox from "../../components/common/Checkbox";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import NoData from "../../components/common/NoData";

const identityProviders = [
  {
    label: "Azure Active Directory",
    value: "Azure Active Directory",
    path: "LDAP://OU=SecurityGroups,OU=Departments,OU=Company,DC=corp,DC=company,DC=com",
    groups: [
      "us_support_group@acmecorp.com",
      "underwriter_group@acmecorp.com",
      "us_intern_group@acmecorp.com",
      "sales_group@acmecorp.com",
      "customer_care@acmecorp.com",
    ],
  },
  { label: "Google", value: "Google", path: "", groups: [] },
  { label: "Okta", value: "Okta", path: "", groups: [] },
];

const SelectGroups = ({
  selectedGroups,
  setSelectedGroups,
  setIdentityProvider,
  identityProvider,
}) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [IDgroups, setIDGroups] = useState<any>([]);
  const theme = useTheme();
  const styles = {
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    width60: {
      width: "60%",
    },
    groupsBox: {
      width: "100%",
      gap: theme.spacing(3),
    },
    gap_4: {
      gap: theme.spacing(4),
    },
    availableGroup: {
      border: `1px solid ${theme.palette.surface20.main}`,
      width: "50%",
      minHeight: "250px",
      borderRadius: "4px",
    },
    listOfGroups: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.5),
      padding: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.surface20.main}`,
    },
    checkbox: {
      "& .MuiSvgIcon-root": { fontSize: "20px", width: "fit-content" },
    },
    groupName: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
  };

  const checkIfChecked = (group) => {
    return selectedGroups.includes(group);
  };

  useEffect(() => {
    if (identityProvider) setIDGroups(identityProvider?.groups);
  }, [identityProvider]);

  useEffect(() => {
    if (searchKeyword) {
      const filteredGroups = identityProvider?.groups?.filter((group) =>
        group.toLowerCase().includes(searchKeyword.toLowerCase())
      );
      setIDGroups(filteredGroups);
    } else {
      setIDGroups(identityProvider?.groups);
    }
  }, [searchKeyword]);

  return (
    <Box sx={{ ...styles.flexCol, ...styles.gap_4, padding: theme.spacing(2) }}>
      <Box sx={styles.flex}>
        <Select
          options={identityProviders}
          value={identityProvider?.value}
          onChange={(event: any) =>
            setIdentityProvider(
              identityProviders?.find(
                (item) => item.value === event.target.value
              )
            )
          }
          label="Identity Provider"
          minWidth={300}
        />
        <Box sx={{ ...styles.flexCol, ...styles.width60 }}>
          <Typography variant="caption" color={theme.palette.surface40.main}>
            Path
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.surface60.main}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {identityProviders?.find(
              (item) => item.value === identityProvider?.value
            )?.path || "-"}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ...styles.flexCol, ...styles.groupsBox }}>
        <Search
          placeholder="Search Groups"
          value={searchKeyword}
          onChange={(event) => setSearchKeyword(event.target.value)}
          onClear={() => setSearchKeyword("")}
          width={"49%"}
        />
        <Box sx={{ ...styles.flex, gap: theme.spacing(2) }}>
          <Box sx={styles.availableGroup}>
            <Box
              sx={{
                padding: theme.spacing(2),
              }}
            >
              <Typography variant="caption">
                Available Groups ({IDgroups?.length || 0})
              </Typography>
            </Box>
            <Box sx={styles.listOfGroups}>
              {IDgroups?.length > 0 ? (
                IDgroups?.map((group) => (
                  <Box sx={styles.groupName}>
                    <Checkbox
                      label={group}
                      checked={checkIfChecked(group)}
                      sx={styles.checkbox}
                      onClick={() => {
                        if (selectedGroups.includes(group)) {
                          setSelectedGroups(
                            selectedGroups.filter((item) => item !== group)
                          );
                        } else {
                          setSelectedGroups([...selectedGroups, group]);
                        }
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                      color={theme.palette.surface80.main}
                    >
                      {group}
                    </Typography>
                  </Box>
                ))
              ) : (
                <NoData customText="No Groups Found" height="250" />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              ...styles.availableGroup,
              backgroundColor: theme.palette.surface20.main,
            }}
          >
            <Box
              sx={{
                padding: theme.spacing(2),
                width: "100%",
              }}
            >
              <Typography variant="caption">
                Selected Groups ({selectedGroups?.length})
              </Typography>
            </Box>
            <Box
              sx={{
                ...styles.listOfGroups,
                width: "100%",
                borderTop: "1px solid #535874",
              }}
            >
              {selectedGroups.map((group) => (
                <Box
                  sx={{
                    ...styles.groupName,
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                    }}
                    color={theme.palette.surface80.main}
                  >
                    {group}
                  </Typography>
                  <IconButton
                    sx={{
                      width: "20px",
                      padding: "0 !important",
                      height: "16px",
                    }}
                    onClick={() =>
                      setSelectedGroups(
                        selectedGroups?.filter((item) => item !== group)
                      )
                    }
                  >
                    <CloseRoundedIcon
                      sx={{
                        width: "16px",
                        color: theme.palette.primaryBlue.main,
                      }}
                    />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectGroups;
