import { useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";

export interface Props {
  color: string;
  label: string;
  textColor?: string;
}

const LegendWithColor = ({ color, label, textColor = "" }: Props) => {
  const theme = useTheme();
  const styles = {
    rowBadge: (color: string) => ({
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: color,
    }),
    legendLabel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
  };

  return (
    <Box sx={styles.legendLabel}>
      <Box sx={styles.rowBadge(color)}></Box>
      <Typography
        variant="caption"
        color={textColor !== "" ? textColor : theme.palette.surface40.main}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default LegendWithColor;
