import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";

const LabelAndValue = ({ labels }) => {
  const theme = useTheme();
  const styles = {
    card: {},
    table: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      rowGap: theme.spacing(1),
    },
  };
  return (
    <Box sx={styles.table}>
      {labels?.map((label) => (
        <>
          <Typography variant="body2" color={theme.palette.surface80.main}>
            {label?.name}
          </Typography>
          <Typography
            variant="caption"
            color={theme.palette.surface80.main}
            sx={{
              justifySelf: "end",
            }}
          >
            {label?.count}
          </Typography>
        </>
      ))}
    </Box>
  );
};

export default LabelAndValue;
